import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Report from "../components/Report";
import AuthService from "../services/auth.service";
import QueryService from "../services/query.service";
import "../App.css";
import * as demographics from "../util/Demographics.js"

let items = [];
const setItems = (newItems) => {
  items = newItems;
}
const currentYear = new Date().getFullYear();

export const Query = (props) => {

  const countries = [
    { label: "Argentina/Mexico", value: "argentina" },
    { label: "China", value: "china" },
    { label: "Kazakhstan", value: "kazakhstan" },
    { label: "Russia", value: "russia" },
    { label: "Singapore", value: "singapore" },
    { label: "Taiwan", value: "taiwan" },
    { label: "Thailand", value: "thailand" },
    { label: "United Arab Emirates", value: "uae" },
    { label: "Saudi Arabia", value: "sa" },
  ];
  
  const [country, setCountry] = useState("taiwan");
  const [disease, setDisease] = useState("fabry");
  const [completedSurvey, setCompletedSurvey] = useState(true);
  const [fromDate, setFromDate] = useState(new Date(currentYear, 0, 1)); //useState(new Date("2022", 3, 1));
  const [toDate, setToDate] = useState(new Date()); //useState(new Date("2022", 5, 30));
  const [fromAge, setFromAge] = useState(1);
  const [toAge, setToAge] = useState(100);
  const [ageOption, setAgeOption] = useState("years");
  const [gender, setGender] = useState({female: true, male : true});
  const [riskLevel, setRiskLevel] = useState({noIncreased: true, increased : true, high : true});
  const [completedSurveyCount, setCompletedSurveyCount] = useState(null);
  const [uncompletedSurveyCount, setUncompletedSurveyCount] = useState(null);
  const [allTimeCount, setAllTimeCount] = useState(null);

  //const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const [message, setMessage] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState(["id","DESC"])

  const resetData = () => {
    setItems([]);
    setTotalItems(0);
    setCompletedSurveyCount(null);
    setUncompletedSurveyCount(null);
    setAllTimeCount(null);
    if ( AuthService.isExpired() === true ){
      setRedirecting(true);
    }
  }
  const onChangeCountry = (e) => {
    const country = e.target.value;
    setCountry(country);
    resetData();
   
  };

  const onChangeDisease = (e) => {
    const disease = e.target.value;
    setDisease(disease);
    resetData();
  };

  const onChangeRiskLevel = (e) => {
    //console.log(`e.target.checked ${e.target.checked}` );
    setRiskLevel( rl => ({ ...rl, [e.target.name]: !rl[e.target.name] }));
    resetData();
  };

  const onChangeGender = (e) => {
    setGender( g => ({ ...g, [e.target.name]: !g[e.target.name] }));
    resetData();
  };

  const onChangeFromAge = (e) => {
    setFromAge(e.target.value);
    resetData();
  };

  const onChangeToAge = (e) => {
    setToAge(e.target.value);
    resetData();
  };

  const onChangeAgeOption = (e) => {
    setAgeOption(e.target.value);
    resetData();
  };

  const onChangeCompletedSurvey = (e) => {
    const completed = e.target.checked;
    setCompletedSurvey(completed);
    resetData();
  };
  /*
  const onChangeResult = (e) => {
    const items = e.target.value;
    setItems(items);
  };
  */
  
  const handleToUpdatePageSize = ( value ) => {
    setPageSize( value );
  };

  const handleToUpdateMessage = ( value ) => {
    setMessage( value );
  };

  const handleToUpdateRedirecting = ( value ) => {
    setRedirecting( value );
  };

  const validateAll = () =>{
    if( !gender.female && !gender.male ){
      setMessage("Please select gender(s).");
      return false;
    }
    if( (!riskLevel.noIncreased && !riskLevel.increased && !riskLevel.high )){
      setMessage("Please select risk level(s).");
      return false;
    }
    setMessage("");
    return true;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if ( !validateAll() ){ return; }
    
    setSearch({
      disease: disease,
      country: country,
      fromDate: fromDate,
      toDate: toDate,
      riskLevel: riskLevel,
      fromAge: fromAge,
      toAge: toAge,
      ageOption: ageOption,
      gender: gender,
      completedSurvey: completedSurvey,
      page: 0,
      size: pageSize,
      orderBy: orderBy
    });
  };

  useEffect(() => {
    if (search !== null) {
      
      QueryService.query(search)
        .then((res) => {
          //setItems(res.data.items);
          items = res.data.items;
          setTotalItems(res.data.totalItems);
          setAllTimeCount(res.data.allTimeCount ? res.data.allTimeCount : 0 );
          search.completedSurvey === true ? setCompletedSurveyCount( res.data.completedSurveyCount ) :
            setUncompletedSurveyCount( res.data.uncompletedSurveyCount );
         
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error.response));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            setRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.response.data ||
                        error.message ||
                        error.toString();
          setMessage(err);
        });
    }
    

  }, [search]);

  
  return (
    <div className="container">
      <Form onSubmit={handleSubmit} >
        <div className="row tg-query form-group">
          <div className="col-sm">
            <label className="mdb-main-label">Country</label>
            <select
              className="form-control"
              name="country"
              value={country}
              onChange={onChangeCountry}
            >
              
              {countries.map((c) => <option value={c.value}>{c.label}</option>)}
            </select>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">Disease</label>
            <select
              className="form-control"
              name="disease"
              value={disease}
              onChange={onChangeDisease}
            >
              <option value="fabry">Fabry</option>
              <option value="gaucher">Gaucher</option>
              <option value="hae">HAE</option>
            </select>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">From Date</label>
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
            />
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">To Date</label>
            <DatePicker
              selected={toDate}
              onChange={(tdate) => setToDate(tdate)}
            />
          </div>
          <div className="col-sm">
              <div className="checkbox">
                <label><input type="checkbox" key="noIncreased" name="noIncreased" checked={riskLevel.noIncreased} 
                        disabled={!completedSurvey} onChange={onChangeRiskLevel}></input> {demographics.NO_RISK}</label>
              </div>
              <div className="checkbox">
                <label><input type="checkbox" key="increased" name="increased" checked={riskLevel.increased} 
                        disabled={!completedSurvey} onChange={onChangeRiskLevel}></input> {demographics.INCREASED_RISK}</label>
              </div>
              <div className="checkbox">
                <label><input type="checkbox" key="high" name="high" checked={riskLevel.high} 
                        disabled={!completedSurvey} onChange={onChangeRiskLevel}></input> {demographics.HIGH_RISK}</label>
              </div>
          </div>       
        </div>
        <div className="row tg-query form-group">
          <div className="col-sm">
            <label className="mdb-main-label">From Age</label>
            <input type="number" min="1" max="100" step="1" value={fromAge} disabled={!completedSurvey} onChange={onChangeFromAge}></input>
          </div>
          <div className="col-sm">
            <label className="mdb-main-label">To Age</label>
            <input type="number" min="1" max="100" step="1" value={toAge} disabled={!completedSurvey} onChange={onChangeToAge}></input>
          </div>
          <div className="col-sm form-check">
            <label><input className="form-check-input" type="radio" value="months" checked={ageOption === 'months'} disabled={!completedSurvey} onChange={onChangeAgeOption}></input>months</label>
            <label><input className="form-check-input" type="radio" value="years" checked={ageOption === 'years'} disabled={!completedSurvey} onChange={onChangeAgeOption}></input>years</label>
          </div>
          
          <div className="col-sm">
              <div className="checkbox">
                <label><input type="checkbox" key="female" name="female" checked={gender.female} disabled={!completedSurvey} onChange={onChangeGender}></input> Female</label>
              </div>
              <div className="checkbox">
                <label><input type="checkbox" key="male" name="male" checked={gender.male} disabled={!completedSurvey} onChange={onChangeGender}></input> Male</label>
              </div>
          </div>      
          <div className="col-sm">
              <div className="checkbox">
                <label> Completed Survey</label>
                <input
                  type="checkbox"
                  name="completedSurvey"
                  value={completedSurvey}
                  onChange={onChangeCompletedSurvey}
                  checked={completedSurvey}
                />
              </div>
            </div>

        </div>
        <div className="row justify-content-center">
          <button type="submit" className="btn btn-primary" >Submit</button>
        </div>
        <div className="row">
          {/*<div className={"alert alert-danger" } role="alert"></div> */}
          <p style={{color: "red"}}> {message}</p>
        </div>
        <Report search={ { disease: disease,
                            country: country,
                            fromDate: fromDate,
                            toDate: toDate,
                            riskLevel: riskLevel,
                            fromAge: fromAge,
                            toAge: toAge,
                            ageOption: ageOption,
                            gender: gender,
                            completedSurvey: completedSurvey,
                            page: 0,
                            orderBy: orderBy }}
                            submit={{value: true}}
                            items={items}
                            totalItems={totalItems}
                            completedSurveyCount={completedSurveyCount}
                            uncompletedSurveyCount={uncompletedSurveyCount}
                            allTimeCount={allTimeCount}
                            updatePageSize={handleToUpdatePageSize}
                            updateMessage={handleToUpdateMessage} 
                            updateRedirecting={handleToUpdateRedirecting} 
                            updateUser={props.updateUser}/>
              
      </Form>
           
      { redirecting ? <Redirect to="/login" /> : null }
    
    </div>
    
  );
};
