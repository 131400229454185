import axios from "axios";
import authHeader from "./auth-header";
import fileDownload from 'js-file-download'

const SM_QUERY_API = process.env.REACT_APP_SM_API_HOST + "/admin/api/query/";
const SM_TEARSHEET_API = process.env.REACT_APP_SM_API_HOST + "/admin/api/tearsheet/";
const SM_EXPORT_API = process.env.REACT_APP_SM_API_HOST + "/admin/api/export/";

const extractFileName = ( contentDispositionValue ) => {
  let filename = "";
  if (contentDispositionValue && contentDispositionValue.indexOf('attachment') !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
      }
  }
  return filename;
}

const extractGender =  ( gender ) => {

  if( gender.female === true && gender.male === true){
    return "All";
  }else if ( gender.female === true ){
    return "Female";
  }else if ( gender.male === true ){
    return "Male";
  }else{
    return "invalid_gender"
  }

}

const getUTCDate = ( date, isToDate ) => {
  
  let searchDate;

  //console.log( "searchDate " + date.toString() ); 
  if( isToDate ){
    searchDate = Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  }else{
    searchDate = Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }

  let utcDate = new Date( searchDate ).toISOString();
 
  //console.log( "utcDate " + utcDate.toString() );
  return utcDate;
 
}

const downloadCsv = ( search ) => {
  
    let url = `${SM_EXPORT_API}${search.disease}/${search.country}/`;
    let config = {
      method: "get",
      params: {
        fromDate: getUTCDate( search.fromDate, false ),
        toDate: getUTCDate( search.toDate, true ),
      },
      headers: {
        Authorization: authHeader(),
      },
    };
    if ( search.completedSurvey === true ){
      config.url = url + "completedsurvey";
      config.params.fromAge = search.fromAge;
      config.params.toAge = search.toAge;
      config.params.gender = extractGender(search.gender);
      config.params.riskLevelNoIncreased = search.riskLevel.noIncreased;
      config.params.riskLevelIncreased = search.riskLevel.increased;
      config.params.riskLevelHigh = search.riskLevel.high;
    }else{
      config.url = url + "uncompletedsurvey";
    }
    
    axios(config)
      .then(function (response) {
        let filename = extractFileName(response.headers['content-disposition']);
        fileDownload(response.data, filename);
      
      }).catch( (error) => {
        console.log(error);
        if (error.response) {
          console.log('Error ', error.response.status);
        } else {
          console.log('Error ', error.message);
        }
    });
    
}

const getCompletedSurvey = (search) => {
 
  let url = `${SM_QUERY_API}${search.disease}/${search.country}/completedsurvey`;
 
  let data = {  
    fromAge: search.fromAge,
    toAge:  search.toAge,
    gender: extractGender(search.gender),
    riskLevel: search.riskLevel
  };
            
  return axios({
    url: url,
    method: "post",
    timeout: 8000,
    params: {
      fromDate: getUTCDate( search.fromDate, false ),
      toDate: getUTCDate( search.toDate, true),
      page: search.page,
      size: search.size,
      sort: search.orderBy.toString()
    },
    data: data,
    headers: {
      Authorization: authHeader(),
      Accept: "application/json",
    },
  });
  
};

const getUncompletedSurvey = (search) => {
  let url = `${SM_QUERY_API}${search.disease}/${search.country}/uncompletedsurvey`;
  
  return axios({
    url: url,
    method: "get",
    timeout: 8000,
    params: {
      fromDate: getUTCDate( search.fromDate, false ),
      toDate: getUTCDate( search.toDate, true),
      page: search.page,
      size: search.size,
      sort: search.orderBy.toString()
    },
    headers: {
      Authorization: authHeader(),
      Accept: "application/json",
    },
  });
  
};

const query = (search) => {

  if (search.completedSurvey === true ) {
    return getCompletedSurvey(search);
  }else if ( search.completedSurvey === false ){
    return getUncompletedSurvey(search);
  }

}

const getTearsheet = ( params ) => {
  let url = `${SM_TEARSHEET_API}${params.disease}/${params.country}`;

  return axios({
    url: url,
    method: "get",
    timeout: 8000,
    params: {
      id: params.id,
    },
    headers: {
      Authorization: authHeader(),
      Accept: "application/json",
    },
  });
 
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  query,
  getTearsheet,
  downloadCsv
};
