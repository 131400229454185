import React from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

const fabryHeadCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "id",
  },
  {
    id: "completedSurvey",
    numeric: false,
    disablePadding: true,
    label: "Completed Survey",
  },
  {
    id: "downloadedTearSheet",
    numeric: false,
    disablePadding: true,
    label: "Downloaded Tearsheet",
  },
  {
    id: "userAge",
    numeric: true,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "userGender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
  },
  {
    id: "riskLevel",
    numeric: false,
    disablePadding: true,
    label: "Risk",
  },
  {
    id: "initialScreenerScore",
    numeric: true,
    disablePadding: true,
    label: "Initial Score",
  },
  {
    id: "completedFollowup",
    numeric: false,
    disablePadding: true,
    label: "Completed Followup",
  },
  {
    id: "followupScore",
    numeric: true,
    disablePadding: true,
    label: "Followup Score",
  },
  {
    id: "userStoriesScore",
    numeric: true,
    disablePadding: true,
    label: "User Stories Score",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    id: "clientUrl",
    numeric: false,
    disablePadding: true,
    label: "Client URL",
  },
  {
    id: null,
    numeric: false,
    disablePadding: true,
    label: "Tearsheet",
  },
  
];

const gaucherHeadCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "id",
  },
  {
    id: "completedSurvey",
    numeric: false,
    disablePadding: true,
    label: "Completed Survey",
  },
  {
    id: "downloadedTearSheet",
    numeric: false,
    disablePadding: true,
    label: "Downloaded Tearsheet",
  },
  {
    id: "userAge",
    numeric: true,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "userGender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
  },
  {
    id: "riskLevel",
    numeric: false,
    disablePadding: true,
    label: "Risk",
  },
  {
    id: "initialScreenerScore",
    numeric: true,
    disablePadding: true,
    label: "Initial Score",
  },
  {
    id: "completedFollowup1",
    numeric: false,
    disablePadding: true,
    label: "Completed Followup1",
  },
  {
    id: "followupScore1",
    numeric: true,
    disablePadding: true,
    label: "Followup1 Score",
  },
  {
    id: "completedFollowup2",
    numeric: false,
    disablePadding: true,
    label: "Completed Followup2",
  },
  {
    id: "followupScore2",
    numeric: true,
    disablePadding: true,
    label: "Followup2 Score",
  },
  {
    id: "completedFollowup3",
    numeric: false,
    disablePadding: true,
    label: "Completed Followup3",
  },
  {
    id: "followupScore3",
    numeric: true,
    disablePadding: true,
    label: "Followup3 Score",
  },
  {
    id: "userStoriesScore",
    numeric: true,
    disablePadding: true,
    label: "User Stories Score",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  {
    id: "clientUrl",
    numeric: false,
    disablePadding: true,
    label: "Client URL",
  },
  {
    id: null,
    numeric: false,
    disablePadding: true,
    label: "Tearsheet",
  },
  
];

const getHeadCells = (disease ) => {
  return (disease === "fabry" || disease === "hae" ) ? fabryHeadCells : gaucherHeadCells;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e9ecef",
    color: "#212529",
  },
  
}))(TableCell);

export const EnhancedTableHead = (props) => {
  const {
    classes,
    numSelected,
    order,
    orderBy,
    //onSelectAllClick,
    onRequestSort,
    rowCount,
    disease
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/*<TableCell padding="checkbox"></TableCell>
        */}
        { getHeadCells(disease).map((headCell) => (
          <StyledTableCell
           
            className='mdb-main-label'
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "none"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

const reportHeadCells = [

  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "id",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "Created At",
  },
  
  {
    id: "userAge",
    numeric: true,
    disablePadding: true,
    label: "Age",
  },
  {
    id: "userGender",
    numeric: false,
    disablePadding: true,
    label: "Gender",
  },
  {
    id: "riskLevel",
    numeric: false,
    disablePadding: true,
    label: "Risk",
  },
  {
    id: "downloadedTearSheet",
    numeric: false,
    disablePadding: true,
    label: "Downloaded Tearsheet",
  },
   
];

export const reportTableHead = (props) => {
  const {
    classes,
    numSelected,
    order,
    orderBy,
    onRequestSort,
    rowCount,
    disease
  } = props;
  
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        
                      
      </TableRow>
    </TableHead>
  );
}

reportTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};