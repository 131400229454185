import React, {useRef} from "react";
import { useReactToPrint } from "react-to-print";
import "bootstrap/dist/css/bootstrap.min.css";
import "../symptom-screener.css";

const getEvaluationHtml = ( value ) => {
  let html = "invalid assessment case";
  if (value && (typeof value === 'number' || parseInt(value))) {
    switch (parseInt(value)) {
      case 1:
        html = 'you are at no increased risk to have Fabry disease.';
        break;
      case 2:
        html = 'you are at possible risk to have Fabry disease.';
        break;
      case 3:
        html = 'you are at an increased risk to have Fabry disease.';
        break;
      default:
        alert('invalid assessment case');
      }
  }else {
    alert('invalid assessment case');
  }
  return html;
}

const getQ1PromptText = () => {

  return "Are you learning about your disease risk for:";

};

const getQ1ResponseText = ( value ) => {
  
  if( value === "Self"){

    return "Self";
  
  }else {
   
    return "Other";
  
  }
}

const getQ2PromptText = () => {
   
  return "What is your gender?"

}

const getQ2ResponseText = ( value ) => {
 
 if( value === "Male"){

   return "Male";
 
 }else {
   
   return "Female";
 
 }
}
const getQ3PromptText = () => {
return "What is your age?";
}

const getQ4PromptText = () => {

return "Has anyone in your family been diagnosed with:";

}

const getQ4PartPromptText = ( value ) => {
  
if( value === "Fabry disease" ){

  return "Fabry disease";

}else if ( value === "Kidneys do not work as well as they should (renal failure) when they were under 50 years old"){

  return "Kidneys do not work as well as they should (renal failure) when they were under 50 years old";

}else if ( value === "Bottom left part (Left ventricle) of the heart is too big (left ventricular hypertrophy) - when they were under 50 years old" ){
   
   return "Bottom left part (Left ventricle) of the heart is too big (left ventricular hypertrophy) - when they were under 50 years old";

}else if( value === "Heart muscle is too big (hypertrophic cardiomyopathy) - when they were less than 50 years old"){
  
  return "Heart muscle is too big (hypertrophic cardiomyopathy) - when they were less than 50 years old";

}else if ( value === "A positive newborn screening test for Fabry"){

  return "A positive newborn screening test for Fabry";

}else{
  
  return "invalid prompt";

}
}

const getQ4ResponseText = ( value ) => {

if( value === "Yes"){
  
  return "Yes";

}else if ( value === "No" ){
  
  return "No";

}else if ( value === "Don't Know" ){
  
  return "Don't Know";

}else {
  
  return "invalid response";

}

}

function getQ5PromptText(){
  
return "Has your doctor told you that you have:";

}

function getQ5ResponseText( value ){

if( value === "A bottom left side of the heart that is too big (left ventricular hypertrophy)"){
  
  return "A bottom left side of the heart that is too big (left ventricular hypertrophy)";

}else if ( value === "Fabry disease" ){
  
  return "Fabry disease";

}else if ( value === "A positive test for Fabry as a newborn baby" ){
  
  return "A positive test for Fabry as a newborn baby";

}else if ( value === "A heart biopsy that found stored material in your heart muscle" ){
  
  return "A heart biopsy that found stored material in your heart muscle";

}else if ( value === "Elevated protein in urine" ){
  
  return "Elevated protein in urine";

}else if ( value === "Heart rate is too slow (bradycardia)" ){
  
  return "Heart rate is too slow (bradycardia)";

}else if ( value === "Kidneys don't work as well as they should (renal failure) - when you were under 50 years old" ){
  
  return "Kidneys don't work as well as they should (renal failure) - when you were under 50 years old";

}else if ( value === "Whorl pattern found in the cornea on eye exam (corneal whorl or verticillata)" ){
  
  return "Whorl pattern found in the cornea on eye exam (corneal whorl or verticillata)";

}else {
  
  return "invalid response";

}
    
}

function getQ6PromptText(){
return "Have you experienced any of the following:";
}

function getQ6ResponseText( value ){
   
if( value === "Decreased to no sweating"){

  return "Decreased to no sweating";

}else if ( value === "Burning pain or tingling in hands or feet" ){

  return "Burning pain or tingling in hands or feet";

}else if ( value === "Severe whole body pain crises with fever or overexertion" ){

  return "Severe whole body pain crises with fever or overexertion";

}else if ( value === "Heat intolerance" ){

  return "Heat intolerance";
  
}else if ( value === "Stroke (or mini-stroke) when you were under 50 years old" ){
  
  return "Stroke (or mini-stroke) when you were under 50 years old";
  
}else {

  return "invalid response";
  
}

}
function getQ8PromptText(){

return "Has your doctor told you of any of the following:";

}

function getQ8ResponseText( value ){
       
if( value === "Heartbeat doesn't feel right (fluttery, too fast, pounds or skips a beat) (Heart  conduction abnormalities)"){

  return "Heartbeat doesn't feel right (fluttery, too fast, pounds or skips a beat) (Heart  conduction abnormalities)";

}else if ( value === "Heart beats too fast or too slow (heart rhythm problems)" ){

  return "Heart beats too fast or too slow (heart rhythm problems)";

}else if ( value === "Chest pain" ){

  return "Chest pain";

}else if ( value === "Leaking heart valve (cardiac valve regurgitation)" ){

  return "Leaking heart valve (cardiac valve regurgitation)";
  
}else if ( value === "Narrowed heart arteries (ischemic heart disease)" ){
  
  return "Narrowed heart arteries (ischemic heart disease)";
  
}else if ( value === "Tests show the heart muscle is becoming too thick and stiff (hypertrophic cardiomyopathy)" ){

  return "Tests show the heart muscle is becoming too thick and stiff (hypertrophic cardiomyopathy)";
  
}else if ( value === "Feeling tired all the time (fatigue)" ){
  
  return "Feeling tired all the time (fatigue)";
  
}else if ( value === "Mini-stroke (TIA)" ){

  return "Mini-stroke (TIA)";
  
}else if ( value === "Temporary partial vision loss, blind spots, zig-zag lines or seeing stars (visual migraines)" ){
  
  return "Temporary partial vision loss, blind spots, zig-zag lines or seeing stars (visual migraines)";
  
}else if ( value === "Dizziness (vertigo)" ){

  return "Dizziness (vertigo)";
  
}else if ( value === "Fainting spells (syncope)" ){
  
  return "Fainting spells (syncope)";
  
}else if ( value === "Kidneys are not working properly (renal disease)" ){

  return "Kidneys are not working properly (renal disease)";
  
}else if ( value === "Decreased GFR blood test (type of kidney value)" ){
  
  return "Decreased GFR blood test (type of kidney value)";
  
}else if ( value === "Elevated GFR blood test (a kidney function test)" ){

  return "Elevated GFR blood test (a kidney function test)";
  
}else if ( value === "Elevated serum creatinine blood test" ){
  
  return "Elevated serum creatinine blood test";
  
}else if ( value === "Chronic diarrhea" ){

  return "Chronic diarrhea";
  
}else if ( value === "Abdominal pain" ){
  
  return "Abdominal pain";
  
}else if ( value === "Constipation" ){

  return "Constipation";
  
}else if ( value === "Diarrhea with alternating constipation" ){
  
  return "Diarrhea with alternating constipation";
  
}else if ( value === "Bloating" ){

  return "Bloating";
  
}else if ( value === "Depression" ){
  
  return "Depression";
  
}else if ( value === "Panic Attacks" ){

  return "Panic Attacks";
  
}else if ( value === "Anxiety" ){
  
  return "Anxiety";
  
}else if ( value === "Ringing in the ears (tinnitus)" ){

  return "Ringing in the ears (tinnitus)";
  
}else if ( value === "Hearing loss" ){
  
  return "Hearing loss";
  
}else if ( value === "Red dots or non-itchy rash on lower belly (angiokeratoma)" ){

  return "Red dots or non-itchy rash on lower belly (angiokeratoma)";
  
}else if ( value === "Fatigue (feeling exhausted or tired)" ){
  
  return "Fatigue (feeling exhausted or tired)";
  
}else if ( value === "Exercise causes burning pain or overheating (exercise intolerance)" ){

  return "Exercise causes burning pain or overheating (exercise intolerance)";
  
}else if ( value === "Extremely sensitive to cold (cold intolerance)" ){
  
  return "Extremely sensitive to cold (cold intolerance)";
  
}else if ( value === "Fingers and toes turn white and blue when cold (Raynaud phenomenon)" ){

  return "Fingers and toes turn white and blue when cold (Raynaud phenomenon)";
  
}else if ( value === "Swelling in the hands or feet from extra fluid (edema in extremities)" ){
  
  return "Swelling in the hands or feet from extra fluid (edema in extremities)";
  
}else if ( value === "Numbness or tingling in hands and feet (neuropathy)" ){

  return "Numbness or tingling in hands and feet (neuropathy)";
  
}else if ( value === "Erection of the penis that lasts longer than 4 hours without medication (priapism)" ){
  
  return "Erection of the penis that lasts longer than 4 hours without medication (priapism)";
  
}else {

  return "invalid response";
  
}
}

function getQ9PromptText(){

return "Rate how much each of the following stories sound like you:";

}

function getQ9KeyText( value ){

if( value === "Late Onset Fabry Male"){

  return "Late Onset Fabry Male";

}else if( value === "Classic Fabry Male"){

  return "Classic Fabry Male";

}else if ( value === "Taiwan Splice Male" ){

  return "Taiwan Splice Male";
  
}else if( value === "Taiwan Splice Female Baby"){

  return "Taiwan Splice Female Baby";

}else if ( value === "Taiwan Splice Female" ){

  return "Taiwan Splice Female";

}else if ( value === "Classic Fabry Female" ){

  return "Classic Fabry Female";

}else {

  return "invalid response";
  
}

}

function getQ9ResponseText( value ){
   
if( value === "Not at all"){

  return "Not at all";

}else if ( value === "Not much" ){

  return "Not much";

}else if ( value === "Somewhat" ){

  return "Somewhat";

}else if ( value === "A lot" ){

  return "A lot";
  
}else {

  return "invalid response";
  
}

}


export default function FabryTearsheet( props ){

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //onClick={() => window.print()}
  const imageStyle = {
    width: "75px",
    height: "100px",
    display: "inline-block",
    position: "absolute"
  };

  const divStyle = {
    border: "1px solid #ccc",
    paddingLeft: "20px",
    backgroundColor: "#f3f4f4",
    width: "100%"
    
  };
  const h3Style ={
    color: "rgb(158,78,135)",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "18px",
    margin: "20px 0px 18px"
  }
  const h4Style ={
    fontFamily: "Robotto",
    fontSize: "14px",
    fontWeight: "bold"
  }

  const tableStyle = {
    width: "100%",
    border: "1px solid #efefef"
  };

  const thQuestionStyle= {
    width: "40%"
  };

  const thResponseStyle= {
    width: "60%"
  }

  const tearsheetDivStyle = {
    border: "1px solid #ccc",
    padding: "20px"
  }

  const h1Style = {
    fontSize: "28px",
    fontWeight: "700",
    color: "#9e4e87"
  }
  
 
const question1Response = () => {
  if (props.data.userResponses && props.data.userResponses.question1Response) {
      return <tr><td><b>{getQ1PromptText()}</b></td><td><p>{getQ1ResponseText(props.data.userResponses.question1Response.value)}</p></td></tr>;
  }
}
const question2Response = () => {
  if (props.data.userResponses && props.data.userResponses.question2Response) {
    return <tr><td><b>{ getQ2PromptText()} </b></td><td><p>{ getQ2ResponseText( props.data.userResponses.question2Response.value) }</p></td></tr>;
  }
}
const question3Response = () => {
  if (props.data.userResponses && props.data.userResponses.question3Response) {
    return <tr><td><b>{ getQ3PromptText()}</b></td><td><p>{ props.data.userResponses.question3Response.value }</p></td></tr>;
  }
}

const question4Prompt = () => {
  if (props.data.userResponses && props.data.userResponses.question4Response) {
    return <tr><td colSpan="2"><b>{getQ4PromptText()}</b></td></tr>;
  }
}

  return (
      
  <div className="container">
    <div className="row">
      <div className="symptom-screener-dialogue-item" >
        <div style={imageStyle}>
          <img src={process.env.PUBLIC_URL + '/gene.png'}></img>
        </div>
        <div className="symptom-screener-dialogue-bubble">
          <div className="symptom-screener-dialogue-prompt">
            <p>The report below contains the results of your Fabry risk assessment. You can print this for your records, as well as share it electronically with your doctor and other medical professionals.</p>
            <p><b><i>Based on the symptoms you shared on SymptomMatcher,&nbsp;</i><i id="symptom-screener-assessment-evaluation">{getEvaluationHtml(props.data.evaluation)}</i></b></p>
            <p>Please review the information below with your doctor and discuss next steps such as testing for Fabry disease or obtaining a referral to a Fabry expert.</p>
          </div>
        </div>
      </div>
      <div className="row symptom-screener-tearsheet">
        {/*<div className="symptom-screener-button-container">
          <button className="symptom-screener-print-button" type="button" onClick={handlePrint}>
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Print results for your doctor</div>
          </button>
          <button className="symptom-screener-result-button"  type="button">
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Read more about Fabry disease</div>
          </button>
        </div>
        <br />
        <br />
        */}
           
        <div style={divStyle}>
          <h3 style={h3Style}>Your Personalized SymptomMatcher Results</h3>
        </div>
      
        <div style={tearsheetDivStyle}>
          {/*
          <h4 style = {h4Style}><b>Patient Information and Instructions:</b></h4>
          <hr />
          <h1 style={h1Style}>Physician Tear Sheet</h1>
          <h1 style={h1Style}>Fabry disease</h1>*/}
          <h1 style={h1Style}>Indicators/Symptoms</h1>
          <p><i>These are symptoms your patient has said they have, they don't have, or they are not sure if they have. The symptoms are grouped according to likelihood of frequency in a person who has this presentation of Fabry disease.</i></p>
          <table style={tableStyle}>
            <thead><tr>
              <th style={thQuestionStyle}>Question</th>
              <th style={thResponseStyle}>Response</th>
            </tr></thead>
            <tbody id="symptom-screener-assessment-user-responses">
              {question1Response()}
              {question2Response()}
              {question3Response()}
              {question4Prompt()}
              
              {
                Object.keys(props.data.userResponses.question4Response.value).map((part)=>{
                  return <tr><td><p>{props.data.userResponses.question4Response.value[part].prompt}</p></td>
                             <td><p>{props.data.userResponses.question4Response.value[part].value}</p></td></tr>

              })}
              <tr><td><b>{getQ5PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question5Response.value.map( (value) => {
                          return <li> {getQ5ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
              </tr>
              <tr><td><b>{getQ6PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question6Response.value.map( (value) => {
                          return <li> {getQ6ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
              </tr> 
              <tr>
                { props.data.userResponses.question8Response != null ? 
                  <td><b>{getQ8PromptText()}</b></td> : ""}
                { props.data.userResponses.question8Response != null ? 
                  <td>
                    <ul>
                      {props.data.userResponses.question8Response.value.map( (value) => {
                        return <li>{getQ8ResponseText( value )}</li>
                      })}
                    </ul>
                  </td> :""}
              </tr>
              { props.data.userResponses.question9Response != null ? 
              <tr>
                <td colSpan="2"><b>{getQ9PromptText()}</b></td>
              </tr> : 
              ""}
              { props.data.userResponses.question9Response != null ? 
                Object.keys( props.data.userResponses.question9Response.value).map((key) => {
                    return  <tr>
                              <td><p>{getQ9KeyText( key )}</p></td>
                              <td><p>{getQ9ResponseText( props.data.userResponses.question9Response.value[key].value )}</p></td>
                            </tr>
                }): ""
              }    
                
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
        
  );
};

