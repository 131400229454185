import React, {useRef} from "react";
import { useReactToPrint } from "react-to-print";
import "bootstrap/dist/css/bootstrap.min.css";
import "../symptom-screener.css";
import * as Demographics from "../util/Demographics";

const getEvaluationHtml = ( value ) => {
  let html = "invalid assessment case";
  if (value && (typeof value === 'number' || parseInt(value))) {
    switch (parseInt(value)) {
      case 1:
        html = 'you are at no increased risk to have Hereditary Angioedema (HAE).';
        break;
      case 2:
        html = 'you are at possible risk to have Hereditary Angioedema (HAE).';
        break;
      case 3:
        html = 'you are at an increased risk to have Hereditary Angioedema (HAE).';
        break;
      default:
        alert('invalid assessment case');
      }
  }else {
    alert('invalid assessment case');
  }
  return html;
}

function getQ4PromptText(){
  
  return "Has anyone in your family been diagnosed with the following:";

}

function getQ4PartPromptText( value ){
    
  if( value === "Hereditary angioedema" ){
  
    return value;
  
  }else if ( value === "Repeated swelling of face, lips, or tongue without hives"){
  
    return value;
  
  }else if ( value === "Allergic reaction of their face or throat swelling caused by high blood pressure medication [specifically angiotensin-converting enzyme [ACE] inhibitors]" ){
     
     return value;
  
  }else if( value === "Allergic reaction of face or throat swelling caused by starting birth control pills or hormone replacement therapy"){
    
    return value;
  
  }else if ( value === "Throat or tongue swelling that lead to their death that was not caused by a severe reaction to a bee sting or peanut allergy"){
  
    return value;
  
  }else{
    
    return "invalid prompt";
  
  }
}

function getQ4ResponseText( value ){
  
  if( value === "Yes"){
    
    return "Yes";
  
  }else if ( value === "No" ){
    
    return "No";
  
  }else if ( value === "Don't Know" ){
    
    return "Don't Know";
  
  }else {
    
    return "invalid response";
  
  }
  
}

function getQ5PromptText(){
  
  return "Has your doctor told you that you have:";

}
  
function getQ5ResponseText( value ){
  
  if( value === "Hereditary angioedema"){
    
    return value;
  
  }else if ( value === "Low serum C4 (also known as complement component 4 ) in your blood" ){
    
    return value;
  
  }else if ( value === "A low C1 inhibitor level in your blood" ){
    
    return value;
  
  }else if ( value === "Low C1 esterase inhibitor levels in your blood" ){
    
    return value;
  
  }else if ( value === "Drug allergy to the high blood pressure medications called angiotensin-converting enzyme [ACE] inhibitors that causes the throat or face to swell" ){
    
    return value;
  
  }else if ( value === "Drug allergy to estrogen, the hormone in many birth control pills the throat or face to swell" ){
    
    return value;
  
  }else if ( value === "A change/mutation in the SERPING1, F12, ANGPT1, PLG, or  KNG1 gene" ){
    
    return value;
  
  }else if ( value === "Allergic angioedema" ){
    
    return value;
  
  }else {
    
    return "invalid response";
  
  }
      
}

function getQ6PromptText(){

  return "Have you experienced any of the following:";

}
  
function getQ6ResponseText( value ){
     
  if( value === "Repeated swelling in any part of the face, tongue, larynx, or throat without hives or an itching sensation"){
  
    return value;
  
  }else if ( value === "An episode of swelling (edema) in your face, lips, tongue, larynx, or throat unrelated to confirmed food allergy or insect sting" ){

    return value;

  }else if ( value === "Allergic reaction of face or throat swelling caused by high blood pressure medication [specifically angiotensin-converting enzyme [ACE] inhibitors]" ){
  
    return value;
  
  }else {
  
    return "invalid response";
    
  }
  
}

function getQ8PromptText(){

  return "Have you had any of the following:";

}
  
function getQ8ResponseText( value ){
         
  if( value === "Episode of shortness of breath caused by swelling tongue or throat"){
  
    return value;
  
  }else if ( value === "Almost suffocated/asphyxiated from swelling tongue or airway" ){

    return value;

  }else if ( value === "Repeated acute abdominal  cramping episodes lasting more than 24 hours with no fever" ){
  
    return value;
  
  }else if ( value === "Repeated sudden and severe abdominal swelling lasting more than 24 hours with no fever" ){
  
    return value;
    
  }else if ( value === "Been sent to the emergency room to be checked for appendix problems like  appendicitis more than once" ){
    
    return value;
    
  }else if ( value === "Have had face, tongue, or throat swelling that doesn't respond to antihistamines, corticosteroids, or epinephrine" ){
  
    return value;
    
  }else if ( value === "Swelling of the genitals without an injury (genital edema)" ){
    
    return value;
    
  }else if ( value === "Severe swelling after a dental procedure" ){
  
    return value;
    
  }else if ( value === "Severe sudden swelling of face, tongue, or throat during pregnancy" ){
    
    return value;
    
  }else if ( value === "Swollen stomach tissue moving into the tube that connects the stomach to the throat (gastric mucosa inverting into esophagus)" ){
  
    return value;
    
  }else if ( value === "Severe swelling that did respond to a medication called a bradykinin B2 receptor inhibitor or agonist" ){
    
    return value;
    
  }else if ( value === "Allergic reaction of face or throat swelling after starting birth control pills" ){
  
    return value;
    
  }else {
  
    return "invalid response";
    
  }
}

function getQ9PromptText(){

  return "Rate how much each of the following stories sound like you:";

}

function getQ9KeyText( value ){

  if( value === "Patient Experience 1"){
  
    return value;
  
  }else if( value === "Patient Experience 2"){
  
    return value;
  
  }else if ( value === "Patient Experience 3" ){
  
    return value;
    
  }else {
  
    return "invalid response";
    
  }

}

function getQ9ResponseText( value ){
     
  if( value === "Not at all"){
  
    return "Not at all";
  
  }else if ( value === "Not much" ){

    return "Not much";

  }else if ( value === "Somewhat" ){
  
    return "Somewhat";
  
  }else if ( value === "A lot" ){
  
    return "A lot";
    
  }else {
  
    return "invalid response";
    
  }
  
}


export default function HaeTearsheet( props ){

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //onClick={() => window.print()}
  const imageStyle = {
    width: "75px",
    height: "100px",
    display: "inline-block",
    position: "absolute"
  };

  const divStyle = {
    border: "1px solid #ccc",
    paddingLeft: "20px",
    backgroundColor: "#f3f4f4",
    width: "100%"
    
  };
  const h3Style ={
    color: "rgb(158,78,135)",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "18px",
    margin: "20px 0px 18px"
  }
  const h4Style ={
    fontFamily: "Robotto",
    fontSize: "14px",
    fontWeight: "bold"
  }

  const tableStyle = {
    width: "100%",
    border: "1px solid #efefef"
  };

  const thQuestionStyle= {
    width: "40%"
  };

  const thResponseStyle= {
    width: "60%"
  }

  const tearsheetDivStyle = {
    border: "1px solid #ccc",
    padding: "20px"
  }

  const h1Style = {
    fontSize: "28px",
    fontWeight: "700",
    color: "#9e4e87"
  }
  
 
const question1Response = () => {
  if (props.data.userResponses && props.data.userResponses.question1Response) {
      return <tr><td><b>{Demographics.getQ1PromptText()}</b></td><td><p>{Demographics.getQ1ResponseText(props.data.userResponses.question1Response.value)}</p></td></tr>;
  }
}
const question2Response = () => {
  if (props.data.userResponses && props.data.userResponses.question2Response) {
    return <tr><td><b>{ Demographics.getQ2PromptText()} </b></td><td><p>{ Demographics.getQ2ResponseText( props.data.userResponses.question2Response.value) }</p></td></tr>;
  }
}
const question3Response = () => {
  if (props.data.userResponses && props.data.userResponses.question3Response) {
    return <tr><td><b>{ Demographics.getQ3PromptText()}</b></td><td><p>{ props.data.userResponses.question3Response.value }</p></td></tr>;
  }
}

const question4Prompt = () => {
  if (props.data.userResponses && props.data.userResponses.question4Response) {
    return <tr><td colSpan="2"><b>{getQ4PromptText()}</b></td></tr>;
  }
}

  return (
      
  <div className="container">
    <div className="row">
      <div className="symptom-screener-dialogue-item" >
        <div style={imageStyle}>
          <img src={process.env.PUBLIC_URL + '/gene.png'}></img>
        </div>
        <div className="symptom-screener-dialogue-bubble">
          <div className="symptom-screener-dialogue-prompt">
          <p>The report below contains the results of your Hereditary Angioedema (HAE) risk assessment. You can print this for your records, as well as share it electronically with your doctor and other medical professionals.</p>
            <p><b><i>Based on the symptoms you shared on SymptomMatcher,&nbsp;</i><i id="symptom-screener-assessment-evaluation">{getEvaluationHtml(props.data.evaluation)}</i></b></p>
            <p>Please review the information below with your doctor and discuss next steps such as testing for Hereditary Angioedema (HAE) or obtaining a referral to a Hereditary Angioedema (HAE) expert.</p>            
          </div>
        </div>
      </div>
      <div className="row symptom-screener-tearsheet">
        {/*<div className="symptom-screener-button-container">
          <button className="symptom-screener-print-button" type="button" onClick={handlePrint}>
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Print results for your doctor</div>
          </button>
          <button className="symptom-screener-result-button"  type="button">
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Read more about Fabry disease</div>
          </button>
        </div>
        <br />
        <br />
        */}
           
        <div style={divStyle}>
          <h3 style={h3Style}>Your Personalized SymptomMatcher Results</h3>
        </div>
      
        <div style={tearsheetDivStyle}>
          {/*
          <h4 style = {h4Style}><b>Patient Information and Instructions:</b></h4>
          <hr />
          <h1 style={h1Style}>Physician Tear Sheet</h1>
          <h1 style={h1Style}>Fabry disease</h1>*/}
          <h1 style={h1Style}>Indicators/Symptoms</h1>
          <p><i>These are symptoms your patient has said they have, they don't have, or they are not sure if they have. The symptoms are grouped according to likelihood of frequency in a person who has this presentation of HAE.</i></p>
          <table style={tableStyle}>
            <thead><tr>
              <th style={thQuestionStyle}>Question</th>
              <th style={thResponseStyle}>Response</th>
            </tr></thead>
            <tbody id="symptom-screener-assessment-user-responses">
              {question1Response()}
              {question2Response()}
              {question3Response()}
              {question4Prompt()}
              
              {
                Object.keys(props.data.userResponses.question4Response.value).map((part)=>{
                  return <tr><td><p>{props.data.userResponses.question4Response.value[part].prompt}</p></td>
                             <td><p>{props.data.userResponses.question4Response.value[part].value}</p></td></tr>

              })}

              
              <tr><td><b>{getQ5PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question5Response.value.map( (value) => {
                          return <li> {getQ5ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
              </tr>
              <tr><td><b>{getQ6PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question6Response.value.map( (value) => {
                          return <li> {getQ6ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
              </tr>
              <tr>
                { props.data.userResponses.question8Response != null ? 
                  <td><b>{getQ8PromptText()}</b></td> : ""}
                { props.data.userResponses.question8Response != null ? 
                  <td>
                      <ul>{props.data.userResponses.question8Response.value.map( (value) => {
                            return <li> {getQ8ResponseText( value )}</li>
                          })}
                      </ul>
                  </td>  : ""}
              </tr>
              
              { props.data.userResponses.question9Response != null ? 
                <tr>
                  <td colSpan="2"><b>{getQ9PromptText()}</b></td>
                </tr> : ""}
              { props.data.userResponses.question9Response != null ? 
                Object.keys( props.data.userResponses.question9Response.value).map((key) => {
                    return  <tr>
                              <td><p>{getQ9KeyText( key )}</p></td>
                              <td><p>{getQ9ResponseText( props.data.userResponses.question9Response.value[key].value )}</p></td>
                            </tr>
                }): "" }    
                
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
        
  );
};

