import axios from "axios";
import jwt from "jsonwebtoken";

const SM_AUTH_API = process.env.REACT_APP_SM_API_HOST + "/admin/auth/";

const register = (username, email, password) => {
  return axios.post(SM_AUTH_API + "signup", {
    username,
    email,
    password,
  });
};

const login = async (username, password) => {

  return axios
    .post(SM_AUTH_API + "login", {
      username,
      password,
    })
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const isExpired = () => {
  const token = getCurrentUser();
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    const c = now.getTime() > expiry * 1000;
    if( c === true ){
      logout();
      return true;
    }
  }
  
  return false;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { 
  register,
  login,
  logout,
  getCurrentUser ,
  isExpired
};
