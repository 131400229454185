import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {AgeChart, RiskLevelChart, UserEngagementPieChart, FabrySurveyPieChart, FabrySurveyBarChart,
        GaucherSurveyBarChart, GaucherSurveyPieChart, GenderChart,
        FabryQ4Chart, FabryQ5PieChart, 
        FabryQ6PieChart, FabryQ8PieChart, fabryQ8Labels,
        GaucherQ4Chart, GaucherQ5PieChart, 
        GaucherQ6PieChart, GaucherQ7PieChart, gaucherQ7Labels,
        HaeQ4Chart, HaeQ5PieChart, 
        HaeQ6PieChart, HaeQ8PieChart, haeQ8Labels
      } from "./Charts";
import QueryService from "../services/query.service";
import * as demographics from "../util/Demographics.js"

export const prepareQuarterByMonthTableRow = ( name, total, completed, downloaded, uncompleted) => {
    
  let totalValue = ( total === undefined) ? 0 : total;
  let completedValue = ( completed === undefined) ? 0 : completed;
  let downloadedTsValue = (downloaded === undefined) ? 0 : downloaded;
  let uncompletedValue = ( uncompleted === undefined) ? 0 : uncompleted;

  let percentCompleted = ( totalValue === 0 ) ? 0 : ((100*completedValue)/totalValue).toFixed();
  let percentDownloadedTs = ( totalValue === 0 ) ? 0 : ((100*downloadedTsValue)/totalValue).toFixed();
  let percentUncompleted = ( totalValue === 0 ) ? 0 : ((100*uncompletedValue)/totalValue).toFixed();
  
  let dateLabel = name;
  let pd = Date.parse( dateLabel );
  if ( isNaN( pd ) === false ){
    const date = new Date( name );
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    dateLabel = month + " " + year;
  }
  
  return createUserByMonthData( dateLabel, totalValue, completedValue, percentCompleted, percentDownloadedTs, percentUncompleted);
 
}

export const createUserByMonthData = ( name, total, completed, percentCompleted, percentDownloadedTs, percentUncompleted ) => {

  return { name: name, total: total, completed: completed, percentCompleted: percentCompleted, 
           percentDownloadedTs: percentDownloadedTs, percentUncompleted: percentUncompleted };

}

export const createData = (name, value, percent) => {
  return { name, value, percent };
}

export const prepareTableRow = (label, value, total) => {
  
  let v = ( value === undefined) ? 0 : value;
  let t = ( total === 0 ) ? 0 : ((100*v)/total).toFixed();
  
  return createData(label, v, t);

}

export const prepareAgeTableRow = ( label, ageArray, fromIndex, toIndex, totalUsers ) => {
  
  let users = 0; 
  for(let i = fromIndex; i <= toIndex; i++){
     users += ageArray[i];
  }
  
  return createData(label, users, ((100*users)/totalUsers).toFixed());
  
}
 
const STableHead = withStyles(theme => ({
    root: {
      backgroundColor: '#e9ecef'
    }
  }))(TableHead);

  export const ReportTableHead = (props) => {
      
    return (
          <STableHead >
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Reported Age</TableCell>
              <TableCell>Reported Gender</TableCell>
              <TableCell>Risk Assesment</TableCell>
              <TableCell>Downloaded Tear Sheet</TableCell>
            </TableRow>
          </STableHead>    
      );
  }
  export const AllTimeUsersByMonthTable = (props) => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <STableHead >
            <TableRow>
              <TableCell>USERS TO DATE By Month</TableCell>
              <TableCell align="right">Total SM Users</TableCell>
              <TableCell align="right">Completed SM</TableCell>
              <TableCell align="right">Downloaded SM</TableCell>
            </TableRow>
          </STableHead>
          <TableBody>
              {props.data.map((row,index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.year} / {row.month}
                  </TableCell>
                  <TableCell align="right">{row.totalUsers}</TableCell>
                  <TableCell align="right">{row.completedUsers}</TableCell>
                  <TableCell align="right">{row.downloadedTsUsers}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          
        </Table>
        <br></br><br></br>
      </TableContainer>
    );
  }

export const QuarterByMonthTable = (props) => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <STableHead >
            <TableRow>
              <TableCell>QUARTER USERS BY MONTH</TableCell>
              <TableCell align="right">Users</TableCell>
              <TableCell align="right">Completed SM</TableCell>
              <TableCell align="right">% Completed</TableCell>
              <TableCell align="right">% Downloaded Tear Sheet</TableCell>
              <TableCell align="right">Exit % Rate</TableCell>
            </TableRow>
          </STableHead>
          <TableBody>
              {props.data.map((row,index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.total}</TableCell>
                  <TableCell align="right">{row.completed}</TableCell>
                  <TableCell align="right">{row.percentCompleted}%</TableCell>
                  <TableCell align="right">{row.percentDownloadedTs}%</TableCell>
                  <TableCell align="right">{row.percentUncompleted}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          
        </Table>
      </TableContainer>
    );
  }

export const UserEngagementTable = (props) => {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <STableHead >
          <TableRow>
            <TableCell>USER ENGAGEMENT</TableCell>
            <TableCell align="right">Users</TableCell>
            <TableCell align="right">Percentage</TableCell>
          </TableRow>
        </STableHead>
        <TableBody>
            {props.data.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
                <TableCell align="right">{row.percent}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        
      </Table>
    </TableContainer>
  );
}

export const RiskLevelTable = (props) => {
    return (
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <STableHead >
            <TableRow>
              <TableCell>RISK LEVEL</TableCell>
              <TableCell align="right">Users</TableCell>
              <TableCell align="right">Percentage</TableCell>
            </TableRow>
          </STableHead>
          <TableBody>
              {props.data.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                  <TableCell align="right">{row.percent}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          
        </Table>
      </TableContainer>
    );
  }
  
  export const GenderTable = (props) => {
      return (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <STableHead >
              <TableRow>
                <TableCell>GENDER</TableCell>
                <TableCell align="right">Users</TableCell>
                <TableCell align="right">Percentage</TableCell>
              </TableRow>
            </STableHead>
            <TableBody>
                {props.data.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                    <TableCell align="right">{row.percent}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            
          </Table>
        </TableContainer>
      );
    }
  
    export const AgeTable = (props) => {
      return (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <STableHead >
              <TableRow>
                <TableCell>AGE</TableCell>
                <TableCell align="right">Users</TableCell>
                <TableCell align="right">Percentage</TableCell>
              </TableRow>
            </STableHead>
            <TableBody>
                {props.data.map((row, index) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                    <TableCell align="right">{row.percent}%</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            
          </Table>
        </TableContainer>
      );
    }

  export const AgeDiv = (props) => {
    return (
        <div>
          <div className="row">
            <div className="col-sm-12">
              <AgeChart data= { props.data.completedSurveyCount.age.map((d,index) => d)} /> 
            </div>
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>
          <div className="row">
            <div className="col-sm-5">
              <AgeTable data= 
                              {[prepareAgeTableRow('Under 5 years',props.data.completedSurveyCount.age, 0, 3, props.data.totalItems),
                                prepareAgeTableRow('5 to 17 years',props.data.completedSurveyCount.age, 4, 16, props.data.totalItems),
                                prepareAgeTableRow('18 to 24 years',props.data.completedSurveyCount.age, 17, 23, props.data.totalItems),
                                prepareAgeTableRow('25 to 44 years',props.data.completedSurveyCount.age, 24, 43, props.data.totalItems),
                                prepareAgeTableRow('45 to 64 years',props.data.completedSurveyCount.age, 44, 63, props.data.totalItems),
                                prepareAgeTableRow('65 years and over',props.data.completedSurveyCount.age, 64, 99, props.data.totalItems),
                                prepareTableRow('TOTAL',props.data.totalItems,props.data.totalItems)
                               ]}>
                                
              </AgeTable>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-5"></div>
          </div>
          <p style={{paddingBottom: "20px"}}></p>
        </div>);
  }

export const RiskLevelDiv = (props) => {
    return (
        <>
          <div className="row">
            <div className="col-sm-5">
              <RiskLevelChart data={[props.data.completedSurveyCount.riskLevel.high, 
                                     props.data.completedSurveyCount.riskLevel.increased,
                                     props.data.completedSurveyCount.riskLevel.noIncreased]}/>
            </div> 
            <div className="col-sm-2"></div>
            <div className="col-sm-5">
            <RiskLevelTable data= 
                            {[prepareTableRow(demographics.HIGH_RISK,props.data.completedSurveyCount.riskLevel.high, props.data.totalItems),
                              prepareTableRow(demographics.INCREASED_RISK,props.data.completedSurveyCount.riskLevel.increased, props.data.totalItems),
                              prepareTableRow( demographics.NO_RISK,props.data.completedSurveyCount.riskLevel.noIncreased, props.data.totalItems),
                              prepareTableRow('TOTAL',props.data.totalItems,props.data.totalItems)
                              ]}></RiskLevelTable>
            </div>
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>
        </>);
}
export const FabryQuestion4Div = (props) => {
  return (
        <>
        <hr style={{paddingBottom: "20px"}}></hr>
        <div className="row">
            <div className="col-sm-6">
              Family diagnosis of Fabry disease
              <FabryQ4Chart data= {[props.data.completedSurveyCount.q4.partA.Yes,
                                   props.data.completedSurveyCount.q4.partA.No,
                                   props.data.completedSurveyCount.q4.partA["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              Family diagnosis of renal failure under age 50
              <FabryQ4Chart data= {[props.data.completedSurveyCount.q4.partB.Yes,
                                    props.data.completedSurveyCount.q4.partB.No,
                                    props.data.completedSurveyCount.q4.partB["Don't Know"]  ]}/>
            </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-sm-6">
              Family diagnosis of left ventricular hypertrophy under 50 years old
              <FabryQ4Chart data= {[props.data.completedSurveyCount.q4.partC.Yes,
                                   props.data.completedSurveyCount.q4.partC.No,
                                   props.data.completedSurveyCount.q4.partC["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              Family diagnosis of hypertrophic cardiomyopathy under 50 years old
              <FabryQ4Chart data= {[props.data.completedSurveyCount.q4.partD.Yes,
                                    props.data.completedSurveyCount.q4.partD.No,
                                    props.data.completedSurveyCount.q4.partD["Don't Know"]  ]}/>
            </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-sm-6">
              Positive newborn screening test for Fabry in family
              <FabryQ4Chart data= {[props.data.completedSurveyCount.q4.partE.Yes,
                                   props.data.completedSurveyCount.q4.partE.No,
                                   props.data.completedSurveyCount.q4.partE["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
            </div>
        </div>
        <hr style={{paddingBottom: "20px"}}></hr>
        </>
        )
}

export const HaeQuestion4Div = (props) => {
  return (
        <>
        <hr style={{paddingBottom: "20px"}}></hr>
        <div className="row">
            <div className="col-sm-6">
              Hereditary angioedema
              <HaeQ4Chart data= {[props.data.completedSurveyCount.q4.partA.Yes,
                                   props.data.completedSurveyCount.q4.partA.No,
                                   props.data.completedSurveyCount.q4.partA["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              Repeated swelling of face, lips, or tongue without hives
              <HaeQ4Chart data= {[props.data.completedSurveyCount.q4.partB.Yes,
                                    props.data.completedSurveyCount.q4.partB.No,
                                    props.data.completedSurveyCount.q4.partB["Don't Know"]  ]}/>
            </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-sm-6">
              Allergic reaction of their face or throat swelling caused by high blood pressure medication [specifically angiotensin-converting enzyme [ACE] inhibitors]
              <HaeQ4Chart data= {[props.data.completedSurveyCount.q4.partC.Yes,
                                   props.data.completedSurveyCount.q4.partC.No,
                                   props.data.completedSurveyCount.q4.partC["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              Allergic reaction of face or throat swelling caused by starting birth control pills or hormone replacement therapy
              <HaeQ4Chart data= {[props.data.completedSurveyCount.q4.partD.Yes,
                                    props.data.completedSurveyCount.q4.partD.No,
                                    props.data.completedSurveyCount.q4.partD["Don't Know"]  ]}/>
            </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-sm-6">
              Throat or tongue swelling that lead to their death that was not caused by a severe reaction to a bee sting or peanut allergy
              <HaeQ4Chart data= {[props.data.completedSurveyCount.q4.partE.Yes,
                                   props.data.completedSurveyCount.q4.partE.No,
                                   props.data.completedSurveyCount.q4.partE["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
            </div>
        </div>
        <hr style={{paddingBottom: "20px"}}></hr>
        </>
        )
}

export const GaucherQuestion4Div = (props) => {
  return (
        <>
        <hr style={{paddingBottom: "20px"}}></hr>
        <div className="row">
            <div className="col-sm-6">
              Gaucher disease
              <GaucherQ4Chart data= {[props.data.completedSurveyCount.q4.partA.Yes,
                                   props.data.completedSurveyCount.q4.partA.No,
                                   props.data.completedSurveyCount.q4.partA["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              A baby who died before 2 years of age
              <GaucherQ4Chart data= {[props.data.completedSurveyCount.q4.partB.Yes,
                                    props.data.completedSurveyCount.q4.partB.No,
                                    props.data.completedSurveyCount.q4.partB["Don't Know"]  ]}/>
            </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-sm-6">
              A baby who died before birth (Still birth of fetal demise)
              <GaucherQ4Chart data= {[props.data.completedSurveyCount.q4.partC.Yes,
                                   props.data.completedSurveyCount.q4.partC.No,
                                   props.data.completedSurveyCount.q4.partC["Don't Know"]  ]}/>
            </div>
            <div className="col-sm-6">
              A baby who was born encased in a tight shiny film that looks a little like plastic wrap (Collodion baby)
              <GaucherQ4Chart data= {[props.data.completedSurveyCount.q4.partD.Yes,
                                    props.data.completedSurveyCount.q4.partD.No,
                                    props.data.completedSurveyCount.q4.partD["Don't Know"]  ]}/>
            </div>
        </div>
        <hr style={{paddingBottom: "20px"}}></hr>
        </>
        )
}

export const QuarterByMonthDiv = (props) => {
  return (
        <div>
          <div className="row">
                <div className="col-sm-12">
                  <QuarterByMonthTable data= 
                  
                  {[prepareQuarterByMonthTableRow(props.data.uncompletedSurveyCount.quarterByMonthSurvey.months.m1d,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month1Completed + props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month1Uncompleted,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month1Completed,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month1DownloadedTearsheet,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month1Uncompleted
                    ),
                    prepareQuarterByMonthTableRow(props.data.uncompletedSurveyCount.quarterByMonthSurvey.months.m2d,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month2Completed + props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month2Uncompleted, 
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month2Completed,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month2DownloadedTearsheet,
                    props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month2Uncompleted
                  ),
                  prepareQuarterByMonthTableRow(props.data.uncompletedSurveyCount.quarterByMonthSurvey.months.m3d,
                  props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month3Completed + props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month3Uncompleted, 
                  props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month3Completed,
                  props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month3DownloadedTearsheet,
                  props.data.uncompletedSurveyCount.quarterByMonthSurvey.count.month3Uncompleted
                  ),
                  prepareQuarterByMonthTableRow('Quarter Total',
                  props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems,
                  props.data.uncompletedSurveyCount.completedSurvey,
                  props.data.uncompletedSurveyCount.downloadedTearsheet,
                  props.data.totalItems
                  )
                  ]}
                  
                  ></QuarterByMonthTable>
                </div>
          </div>
        </div>)
}

export const UserEngagementDiv = (props) => {
  return (
        <div className="row">
            <div className="col-sm-6">
              <UserEngagementTable data= 
                            {[prepareTableRow('Entered SM',
                                               props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems, 
                                               props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems),
                              prepareTableRow('Exited SM Before Completion',
                                               props.data.totalItems, 
                                               props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems),
                              prepareTableRow('Completed SM',
                                               props.data.uncompletedSurveyCount.completedSurvey, 
                                               props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems),
                              prepareTableRow('Downloaded Tear Sheet',
                                               props.data.uncompletedSurveyCount.downloadedTearsheet, 
                                               props.data.uncompletedSurveyCount.completedSurvey + props.data.totalItems)
                              ]}></UserEngagementTable>
            </div>
            <div className="col-sm-6">
              <UserEngagementPieChart data=
                            {[props.data.uncompletedSurveyCount.completedSurvey, 
                              props.data.totalItems]}/>
            </div>
        </div>)
}

export const FabreySurveyDiv = (props) => {
  return (
    <div className="row">
      <div className="col-sm-6">
        <FabrySurveyBarChart data=
                      {[props.data.uncompletedSurveyCount.completedSurvey, 
                      props.data.uncompletedSurveyCount.startedScreener, 
                      props.data.uncompletedSurveyCount.completedInitialScreener,
                      props.data.uncompletedSurveyCount.completedFollowup]}/>
      </div>
      <div className="col-sm-6">
        <FabrySurveyPieChart data=
                    {[props.data.uncompletedSurveyCount.completedSurvey, 
                    props.data.uncompletedSurveyCount.startedScreener, 
                    props.data.uncompletedSurveyCount.completedInitialScreener,
                    props.data.uncompletedSurveyCount.completedFollowup]}/>
      </div>
    </div>)
}

export const GaucherSurveyDiv = (props) => {
  return (
          <div className="row">
            <div className="col-sm-6">
              <GaucherSurveyBarChart data=
                          {[props.data.uncompletedSurveyCount.completedSurvey, props.data.uncompletedSurveyCount.startedScreener, 
                           props.data.uncompletedSurveyCount.completedInitialScreener, props.data.uncompletedSurveyCount.completedFollowup1,
                           props.data.uncompletedSurveyCount.completedFollowup2, props.data.uncompletedSurveyCount.completedFollowup3]}/>
            </div>
            <div className="col-sm-6">
              <GaucherSurveyPieChart data=
                         {[props.data.uncompletedSurveyCount.completedSurvey, props.data.uncompletedSurveyCount.startedScreener, 
                           props.data.uncompletedSurveyCount.completedInitialScreener, props.data.uncompletedSurveyCount.completedFollowup1,
                           props.data.uncompletedSurveyCount.completedFollowup2, props.data.uncompletedSurveyCount.completedFollowup3]}/>
            </div>
          </div>)
}

export const GaucherQuestion5Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <GaucherQ5PieChart data=
                      {[props.data.completedSurveyCount.q5["Seizures or convulsions (any type)"],
                      props.data.completedSurveyCount.q5["Increased blood levels of angiotensin converting enzyme (ACE),  tartrate-resistant acid phosphatase (TRAP), or chitotriosidase (chito)"],
                      props.data.completedSurveyCount.q5["Problems moving eyes side-to-side to watch a moving object or person (Supranuclear saccadic gaze palsy)"],
                      props.data.completedSurveyCount.q5["Big spleen (splenomegaly)"],
                      props.data.completedSurveyCount.q5["Low platelets levels in the blood  (thrombocytopenia)"],
                      props.data.completedSurveyCount.q5["Swelling of a baby's body before birth (fetal hydrops)"]
                      ]}/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}

export const FabryQuestion5Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <FabryQ5PieChart data=
                      {[props.data.completedSurveyCount.q5["A positive test for Fabry as a newborn baby"],
                      props.data.completedSurveyCount.q5["Whorl pattern found in the cornea on eye exam (corneal whorl or verticillata)"],
                      props.data.completedSurveyCount.q5["A heart biopsy that found stored material in your heart muscle"],
                      props.data.completedSurveyCount.q5["Kidneys don't work as well as they should (renal failure) - when you were under 50 years old"],
                      props.data.completedSurveyCount.q5["A bottom left side of the heart that is too big (left ventricular hypertrophy)"],
                      props.data.completedSurveyCount.q5["Fabry disease"],
                      props.data.completedSurveyCount.q5["Heart rate is too slow (bradycardia)"],
                      props.data.completedSurveyCount.q5["Elevated protein in urine"]
                      ]}/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const GaucherQuestion6Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <GaucherQ6PieChart data=
                      {[props.data.completedSurveyCount.q6["Enlarged spleen AND liver (hepatosplenomegaly)"],
                      props.data.completedSurveyCount.q6["Enlarged liver (hepatomegaly)"],
                      props.data.completedSurveyCount.q6["Easy bruising"],
                      props.data.completedSurveyCount.q6["Leg bone with an \"erlenmeyer flask deformity\" found on X-ray or MRI"]
                      ]}/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const FabryQuestion6Div = (props) => {
  return (
    <>
    <div className="row">
        <div className="col-sm-6">
          <FabryQ6PieChart data=
                      {[props.data.completedSurveyCount.q6["Severe whole body pain crises with fever or overexertion"],
                      props.data.completedSurveyCount.q6["Burning pain or tingling in hands or feet"],
                      props.data.completedSurveyCount.q6["Heat intolerance"],
                      props.data.completedSurveyCount.q6["Stroke (or mini-stroke) when you were under 50 years old"],
                      props.data.completedSurveyCount.q6["Decreased to no sweating"]
                      ]}/>
        </div>
      </div>
    <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const GaucherQuestion7Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <GaucherQ7PieChart data=
                      {[props.data.completedSurveyCount.q7[gaucherQ7Labels[0]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[1]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[2]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[3]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[4]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[5]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[6]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[7]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[8]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[9]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[10]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[11]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[12]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[13]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[14]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[15]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[16]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[17]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[18]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[19]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[20]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[21]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[22]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[23]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[24]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[25]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[26]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[27]],
                        props.data.completedSurveyCount.q7[gaucherQ7Labels[28]]]
                                                     
                      }/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const FabryQuestion8Div = (props) => {
  return (
    <>
    <div className="row">
        <div className="col-sm-9">
          <FabryQ8PieChart data=
          
            {[
              props.data.completedSurveyCount.q8[fabryQ8Labels[0]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[1]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[2]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[3]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[4]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[5]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[6]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[7]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[8]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[9]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[10]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[11]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[12]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[13]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[14]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[15]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[16]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[17]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[18]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[19]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[20]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[21]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[22]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[23]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[24]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[25]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[26]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[27]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[28]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[29]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[30]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[31]],
             props.data.completedSurveyCount.q8[fabryQ8Labels[32]],
             
            ]}/>

        </div>
      </div>
    <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const HaeQuestion5Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-9">
          <HaeQ5PieChart data=
                      {[props.data.completedSurveyCount.q5["Hereditary angioedema"],
                      props.data.completedSurveyCount.q5["Low serum C4 (also known as complement component 4 ) in your blood"],
                      props.data.completedSurveyCount.q5["A low C1 inhibitor level in your blood"],
                      props.data.completedSurveyCount.q5["Low C1 esterase inhibitor levels in your blood"],
                      props.data.completedSurveyCount.q5["Drug allergy to the high blood pressure medications called angiotensin-converting enzyme [ACE] inhibitors that causes the throat or face to swell"],
                      props.data.completedSurveyCount.q5["Drug allergy to estrogen, the hormone in many birth control pills the throat or face to swell"],
                      props.data.completedSurveyCount.q5["A change/mutation in the SERPING1, F12, ANGPT1, PLG, or  KNG1 gene"],
                      props.data.completedSurveyCount.q5["Allergic angioedema"]
                      ]}/>
        </div>
      </div> 
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const HaeQuestion6Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-9">
          <HaeQ6PieChart data=
                      {[props.data.completedSurveyCount.q6["Repeated swelling in any part of the face, tongue, larynx, or throat without hives or an itching sensation"],
                      props.data.completedSurveyCount.q6["An episode of swelling (edema) in your face, lips, tongue, larynx, or throat unrelated to confirmed food allergy or insect sting"],
                      props.data.completedSurveyCount.q6["Allergic reaction of face or throat swelling caused by high blood pressure medication [specifically angiotensin-converting enzyme [ACE] inhibitors]"]
                      ]}/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const HaeQuestion8Div = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-6">
        <HaeQ8PieChart data=
                      {[props.data.completedSurveyCount.q8["Episode of shortness of breath caused by swelling tongue or throat"],
                        props.data.completedSurveyCount.q8["Almost suffocated/asphyxiated from swelling tongue or airway"],
                        props.data.completedSurveyCount.q8["Repeated acute abdominal  cramping episodes lasting more than 24 hours with no fever"],
                        props.data.completedSurveyCount.q8["Repeated sudden and severe abdominal swelling lasting more than 24 hours with no fever"],
                        props.data.completedSurveyCount.q8["Been sent to the emergency room to be checked for appendix problems like  appendicitis more than once"],
                        props.data.completedSurveyCount.q8["Have had face, tongue, or throat swelling that doesn't respond to antihistamines, corticosteroids, or epinephrine"],
                        props.data.completedSurveyCount.q8["Swelling of the genitals without an injury (genital edema)"],
                        props.data.completedSurveyCount.q8["Severe swelling after a dental procedure"],
                        props.data.completedSurveyCount.q8["Severe sudden swelling of face, tongue, or throat during pregnancy"],
                        props.data.completedSurveyCount.q8["Swollen stomach tissue moving into the tube that connects the stomach to the throat (gastric mucosa inverting into esophagus)"],
                        props.data.completedSurveyCount.q8["Severe swelling that did respond to a medication called a bradykinin B2 receptor inhibitor or agonist"],
                        props.data.completedSurveyCount.q8["Allergic reaction of face or throat swelling after starting birth control pills"]
                      ]}/>
        </div>
      </div>
      <hr style={{paddingBottom: "20px"}}></hr>
    </>)
}
export const GenderDiv = (props) => {
  return (
        <>
          <div className="row">
            <div className="col-sm-5">
              <GenderChart data= {[props.data.completedSurveyCount.gender.Female, props.data.completedSurveyCount.gender.Male]}/>
            </div>
            <div className="col-sm-2"></div>
            <div className="col-sm-5">
              <GenderTable data= 
                              {[prepareTableRow('Female',props.data.completedSurveyCount.gender.Female, props.data.totalItems),
                                prepareTableRow('Male',props.data.completedSurveyCount.gender.Male, props.data.totalItems),
                                prepareTableRow('TOTAL',props.data.totalItems, props.data.totalItems)
                               ]}></GenderTable>
            </div> 
           </div>
          <hr style={{paddingBottom: "20px"}}></hr>
        </>)
  }

  export const DownloadButtonDiv = (props) => {
    return (
        <>
          <div className="row justify-content-md-end">
            <button type="button" className="btn btn-primary fa fa-download" 
                    disabled={props.data.loading}
                    onClick={ ()=> QueryService.downloadCsv(props.data.search)}> Download
            </button>       
          </div>
          <hr style={{paddingBottom: "20px"}}></hr>
       </>)
  }

/*

 const ImageText = (props) => (
Now

let props = {
imageUrl:"/js.com",
imageText:""robot""
}
<ImageText {...props} />
Access inside ImageText

{props.imageUrl} or {props.imageText}

 <TearsheetModalDiv data={[props, tearsheetData, selected[0], showModal]} />

export const TearsheetModalDiv = (props) => { //({props, tearsheetData, id}) => {
    return (
      <div>
        <Modal id="printArea" size="xl" scrollable="true" backdrop="static" dialogClassName="modal-90w" show={props.data[3]} onHide={()=>setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{ props.data[0].search.disease === "fabry" ? "Fabry Tearsheet" : "Gaucher Tearsheet"} {props.data[2]}</Modal.Title>
            </Modal.Header>
            <iframe id="iframeToPrint" style={{
                        height: '0px',
                        width: '0px',
                        position: 'absolute'
                    }}>
            </iframe>   
            <Modal.Body>
              {(data.props.search.disease === "fabry") ? 
                <FabryTearsheet disease={props.data[0].search.disease} data={props.data[1].tearsheetData} id={props.data[2]}></FabryTearsheet>
                                                  : ""
              }
              {(props.search.disease === "gaucher") ? 
                <GaucherTearsheet disease={props.data[0].search.disease} data={props.data[1].tearsheetData} id={props.data[2]}></GaucherTearsheet>
                                                    : ""
              }
              {(props.search.disease === "hae") ? 
                <HaeTearsheet disease={props.data[0].search.disease} data={props.data[1].tearsheetData} id={props.data[2]}></HaeTearsheet>
                                                  : ""
              }
              <div className="symptom-screener-button-container">
                  <button className="symptom-screener-print-button" type="button" onClick={() => print()}>
                    <div className="symptom-screener-button-div-1"></div>
                    <div className="symptom-screener-button-div-2">Print results for your doctor</div>
                  </button>
              </div>
            </Modal.Body>
            <Button variant="primary" onClick={ ()=> setShowModal(false)}>Close</Button>
        </Modal>
      </div>)
}

*/
/*
export const FabryTableRow = ( row, isItemSelected, labelId ) => {
  return  <TableRow
            className="tg-row"
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
          >
          <TableCell
            className={classes.cell}
            component="td"
            id={labelId}
            scope="row"
          >
            {row.id}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.completedSurvey !== undefined ? row.completedSurvey.toString() : ""}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.downloadedTearSheet !== undefined ? row.downloadedTearSheet.toString() : ""}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.userAge}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.userGender}
          </TableCell>
          <TableCell className={classes.cell}>
            {getRiskLevelLabel(row.riskLevel)}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.initialScreenerScore}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.completedFollowup !== undefined ? row.completedFollowup.toString(): ""}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.followupScore}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.userStoriesScore}
          </TableCell>
          <TableCell className={classes.cell}>
            {formatDate( row.createdAt )}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.clientUrl}
          </TableCell>
          <TableCell className={classes.cell}>
            {row.completedSurvey ? <Button variant="primary" onClick={ (event) => handleClick(event, row.id) }>Tearsheet</Button>: ""}
          </TableCell>
  </TableRow>;
}
*/