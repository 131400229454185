export const getQ1PromptText = () => {

    return "Are you learning about your disease risk for:";
  
  };
  
  export const getQ1ResponseText = ( value ) => {
    
    if( value === "Self"){
  
      return value;
    
    }else {
     
      return "Other";
    
    }
  }
  
  export const getQ2PromptText = () => {
     
    return "What is your gender?"
  
  }
  
  export const getQ2ResponseText = ( value ) => {
   
   if( value === "Male"){
  
     return value;
   
   }else {
     
     return "Female";
   
   }
  }

  export const getQ3PromptText = () => {
  
    return "What is your age?";
    
  }

  export const HIGH_RISK = 'High';
  export const INCREASED_RISK = 'Increased';
  export const NO_RISK = 'No Risk';
  
  export const getRiskLevelLabel = ( level ) => {
    if( level === 3 ){
      return "High";
    }else if( level === 2 ){
      return "Increased";
    }else if ( level === 1){
      return "No Risk";
    }else{
      return "";
    }
  }