import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { EnhancedTableHead} from "./table/EnhancedTableHead";
import {Modal, Button} from "react-bootstrap";
import AuthService from "../services/auth.service";
import QueryService from "../services/query.service";
import FabryTearsheet from "./FabryTearsheet";
import GaucherTearsheet from "./GaucherTearsheet";
import HaeTearsheet from "./HaeTearsheet";
import { FabryQuestion4Div, FabryQuestion5Div,FabryQuestion6Div,FabryQuestion8Div,AgeDiv, RiskLevelDiv, QuarterByMonthDiv,
         UserEngagementDiv, FabreySurveyDiv, GaucherSurveyDiv, GenderDiv,DownloadButtonDiv,
         AllTimeUsersByMonthTable, ReportTableHead,
         GaucherQuestion4Div, GaucherQuestion5Div, GaucherQuestion6Div, GaucherQuestion7Div,
         HaeQuestion4Div, HaeQuestion5Div, HaeQuestion6Div, HaeQuestion8Div,
        } from "./StatsTables";
import "bootstrap/dist/css/bootstrap.min.css";
import { lighten, makeStyles } from "@material-ui/core/styles";
import "../App.css";
import "../symptom-screener.css";
import moment from 'moment'
import {getRiskLevelLabel} from "../util/Demographics.js"


const styleProp = {
  margin: "5px 5px 0px 0px",
  textDecoration: "none",
  color: "white",
  cursor: "pointer" };

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: "25px"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  cell: {
    fontSize: "12px",
    align: "center",
    padding: "none",
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const print = () => {
  let content = document.getElementById('printArea');
  let w = document.getElementById('iframeToPrint').contentWindow;
  w.document.open();
  w.document.write(content.innerHTML);
  w.document.close();
  w.focus();
  w.print();
}

const formatDate =  ( datetime ) => {

  let date = moment( datetime );
  return date.format ( moment.HTML5_FMT.DATETIME_LOCAL );

}
const formatPrettyDate =  ( datetime ) => {

  let date = moment( datetime );
  return date.format ( "YYYY-MM-DD");

}

let items=null;

export default function TGTable(props) {
  
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalItems, setTotalItems] = React.useState( props.totalItems)
  /// const [items, setItems] = React.useState( props.items);
  const [search, setSearch] = React.useState( props.search);
  const [selected, setSelected] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tearsheetData, setTearsheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
 
  
  if (props.submit.value) {
    items = props.items;
    setPage(0);
    setTotalItems(props.totalItems);
    setSearch(props.search);
    props.submit.value=false;
  }
   
  const handleRequestSort = (event, property) => {
        
    if ( property !== null && search !== null) {
      const isAsc = orderBy === property && order === "asc";
      let newOrder = isAsc ? "desc" : "asc"
      search.orderBy = [ property, newOrder.toUpperCase() ];

      QueryService.query(search)
        .then((res) => {
          items = [...res.data.items];
          setTotalItems(res.data.totalItems);
          setOrder( newOrder );
          setOrderBy(property);
            
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            props.updateRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.response.data ||
                        error.message ||
                        error.toString();
          props.updateMessage(err);
        });
    }
    
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    //console.log("selected id ", id);
    /*if (selectedIndex > -1) {
      //id is in array, remove it to deselect
      setSelected([-1]);
      setSelectedRow(null);
      setShowModal(false)
    } else {*/
      setSelected([id]);
      let row = items.filter((row) => row["id"] === id);
      setSelectedRow(row);
     
      console.log(`country ${props.search.country} disease ${props.search.disease} id ${id}`);
     
      QueryService.getTearsheet({country: props.search.country, disease: props.search.disease, id: id})
        .then((res) => {
         
          setTearsheetData(res.data);
          setShowModal(true);
        })
        .catch((error) => {
          console.log("2. error ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            console.log("2.1 calling logout");
            AuthService.logout();
            //setRedirecting(true);
          }
          const err =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          //setError(err);
        });
   
  };

  const handleChangePage = (event, newPage) => {
       
    if (search !== null) {
      search.page = newPage;
      search.size = rowsPerPage;
      QueryService.query(search)
        .then((res) => {
          items = [...res.data.items];
          setTotalItems(res.data.totalItems);
          setPage(newPage);
            
        })
        .catch((error) => {
          console.log("Error: ", JSON.stringify(error));
          if (error.response && error.response.status === 401) {
            AuthService.logout();
            props.updateUser(undefined);
            props.updateRedirecting(true);
          }
          const err = ( error.response && error.response.data && error.response.data.message ) ||
                        error.message ||
                        error.toString();
          props.updateMessage(err);
        });
    }
    
  };

  const handleChangeRowsPerPage = (event) => {
          
     if (search !== null) {
      let pageSize = parseInt(event.target.value, 10);
      search.page = 0;
      search.size = pageSize;
      setPage(0);
      setRowsPerPage(pageSize);
      props.updatePageSize(pageSize);
      QueryService.query(search)
         .then((res) => {
          items = [...res.data.items];
          setTotalItems(res.data.totalItems);
           
         })
         .catch((error) => {
           console.log("Error: ", JSON.stringify(error));
           if (error.response && error.response.status === 401) {
             AuthService.logout();
             props.updateUser(undefined);
             props.updateRedirecting(true);
           }
           const err = ( error.response && error.response.data && error.response.data.message ) ||
                         error.message ||
                         error.toString();
           props.updateMessage(err);
         });
     }
     
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  
  const fabryOrHaeUncompletedSurveyUserByMonthTable = () => {
    
    if( fabryOrHaeUncompletedSurveyCharts() && (props.uncompletedSurveyCount.quarterByMonthSurvey != null) ) {
       
          return true;
      
    }else{
          return false;
    }
  }

  const gaucherUncompletedSurveyUserByMonthTable = () => {
    
    if( gaucherUncompletedSurveyCharts() && (props.uncompletedSurveyCount.quarterByMonthSurvey != null) ) {
    
      return true;
    
    }
    return false;
    
  }

  const fabryOrHaeUncompletedSurveyCharts = () => {
    
    if( props.search.completedSurvey === false && (props.search.disease === "fabry" || props.search.disease === "hae")
              && props.uncompletedSurveyCount != null && (props.totalItems > 0 || props.uncompletedSurveyCount.completedSurvey > 0)){
              return true;
      }else{
        return false;
      }
  }

  const fabryCompletedSurveyQuestionCharts = () => {
    
    if( props.search.completedSurvey === true && (props.search.disease === "fabry")
              && props.completedSurveyCount != null && props.totalItems > 0 &&
              props.completedSurveyCount.q4 != null){
              return true;
      }else{
        return false;
      }
  }

  const gaucherCompletedSurveyQuestionCharts = () => {
    
    if( props.search.completedSurvey === true && (props.search.disease === "gaucher")
              && props.completedSurveyCount != null && props.totalItems > 0 &&
              props.completedSurveyCount.q4 != null){
              return true;
      }else{
        return false;
      }
  }

  const haeCompletedSurveyQuestionCharts = () => {
    
    if( props.search.completedSurvey === true && (props.search.disease === "hae")
              && props.completedSurveyCount != null && props.totalItems > 0 &&
              props.completedSurveyCount.q4 != null){
              return true;
      }else{
        return false;
      }
  }

  const gaucherUncompletedSurveyCharts = () => {

    if (props.search.completedSurvey === false && props.search.disease === "gaucher" && 
          props.uncompletedSurveyCount != null && (props.totalItems > 0 || props.uncompletedSurveyCount.completedSurvey > 0)){
            return true;
    }
    return false;
  }


    const getFabryTableRow = ( row, isItemSelected, labelId ) => {
      return  <TableRow
        className="tg-row"
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell
          className={classes.cell}
          component="td"
          id={labelId}
          scope="row"
        >
          {row.id}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedSurvey !== undefined ? row.completedSurvey.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.downloadedTearSheet !== undefined ? row.downloadedTearSheet.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userAge}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userGender}
        </TableCell>
        <TableCell className={classes.cell}>
          {getRiskLevelLabel(row.riskLevel)}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.initialScreenerScore}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedFollowup !== undefined ? row.completedFollowup.toString(): ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.followupScore}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userStoriesScore}
        </TableCell>
        <TableCell className={classes.cell}>
          {formatDate( row.createdAt )}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.clientUrl}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedSurvey ? <Button variant="primary" onClick={ (event) => handleClick(event, row.id) }>Tearsheet</Button>: ""}
        </TableCell>
      </TableRow>;
    }
    const getGaucherTableRow = ( row, isItemSelected, labelId ) => {
      return  <TableRow
        className="tg-row"
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
      >
        <TableCell
          className={classes.cell}
          component="td"
          id={labelId}
          scope="row"
        >
          {row.id}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedSurvey !== undefined ? row.completedSurvey.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.downloadedTearSheet !== undefined ? row.downloadedTearSheet.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userAge}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userGender}
        </TableCell>
        <TableCell className={classes.cell}>
          {getRiskLevelLabel(row.riskLevel)}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.initialScreenerScore}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedFollowup1 !== undefined ? row.completedFollowup1.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.followupScore1}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedFollowup2 !== undefined ? row.completedFollowup2.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.followupScore2}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedFollowup3 !== undefined ? row.completedFollowup3.toString() : ""}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.followupScore3}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.userStoriesScore}
        </TableCell>
        <TableCell className={classes.cell}>
          {formatDate( row.createdAt )}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.clientUrl}
        </TableCell>
        <TableCell className={classes.cell}>
          {row.completedSurvey ? <Button variant="primary" onClick={ (event) => handleClick(event, row.id) }>Tearsheet</Button> :""}
        </TableCell>
      </TableRow>;
    }

    const getReportTableRow = ( row, isItemSelected, labelId ) => {
      return  <TableRow
              className="tg-row"
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              >
      <TableCell className={classes.cell}>{formatPrettyDate( row.createdAt )}</TableCell>
      <TableCell
        className={classes.cell}
        component="td"
        id={labelId}
        scope="row"
      >{row.id}</TableCell>
     
      <TableCell className={classes.cell}>{row.userAge}</TableCell>
      <TableCell className={classes.cell}>{row.userGender}</TableCell>
      <TableCell className={classes.cell}>{getRiskLevelLabel(row.riskLevel)}</TableCell>
      <TableCell className={classes.cell}>{row.downloadedTearSheet !== undefined ? row.downloadedTearSheet.toString() : ""}</TableCell>
          
    </TableRow>;
    }

  return (
    <div className={classes.root}>
      <div className="row">
          <Paper className={classes.paper}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                deselectonvlickaway={0}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={items.length}
                  disease={props.search.disease} />
                <TableBody>
                  { 
                    items.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                     
                      return (props.search.disease === "fabry" || props.search.disease === "hae" ) ? 
                                                         getFabryTableRow( row, isItemSelected, labelId ) :
                                                         getGaucherTableRow( row, isItemSelected, labelId );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
              component="div"
              count={props.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage} />
          </Paper>
      </div>
      <div>
        <Modal id="printArea" size="xl" scrollable="true" backdrop="static" dialogClassName="modal-90w" show={showModal} onHide={()=>setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{ props.search.disease === "fabry" ? "Fabry Tearsheet" : "Gaucher Tearsheet"} {selected[0]}</Modal.Title>
            </Modal.Header>
            <iframe id="iframeToPrint" style={{ height: '0px', width: '0px', position: 'absolute' }}> </iframe>   
            <Modal.Body>
              {(props.search.disease === "fabry") ? <FabryTearsheet disease={props.search.disease} data={tearsheetData} id={selected[0]}></FabryTearsheet>
                                                  : "" }
              {(props.search.disease === "gaucher") ? <GaucherTearsheet disease={props.search.disease} data={tearsheetData} id={selected[0]}></GaucherTearsheet>
                                                    : "" }
              {(props.search.disease === "hae") ? <HaeTearsheet disease={props.search.disease} data={tearsheetData} id={selected[0]}></HaeTearsheet>
                                                  : "" }
              <div className="symptom-screener-button-container">
                  <button className="symptom-screener-print-button" type="button" onClick={() => print()}>
                    <div className="symptom-screener-button-div-1"></div>
                    <div className="symptom-screener-button-div-2">Print results for your doctor</div>
                  </button>
              </div>
            </Modal.Body>
            <Button variant="primary" onClick={ ()=> setShowModal(false)}>Close</Button>
        </Modal>
      </div>
      {(props.totalItems > 0) ?
        <DownloadButtonDiv data={props} /> : "" }

      {(props.search.completedSurvey === true && (props.totalItems > 0) && props.completedSurveyCount !== null) ?
      <div className={classes.root}>
      <div className="row">
          <Paper className={classes.paper}>
            <TableContainer>
              <h3>Completion Entries</h3>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                deselectonvlickaway={0}>
                <ReportTableHead/>
                <TableBody>
                  { 
                    items.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                     
                      return (getReportTableRow( row, isItemSelected, labelId ) );
                    })}
                </TableBody>
                
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100, 200]}
              component="div"
              count={props.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage} />
          </Paper>
      </div>
      </div> : "" }


      {(props.search.completedSurvey === true && (props.totalItems > 0) && props.completedSurveyCount !== null) ?
        <>
          <RiskLevelDiv data={props}/>
          <GenderDiv data={props} />
          <AgeDiv data={props}/>
        </> : "" }

      {fabryCompletedSurveyQuestionCharts() ? 
        <>
        <FabryQuestion4Div data={props}/>
        <FabryQuestion5Div data={props}/>
        <FabryQuestion6Div data={props}/>
        <FabryQuestion8Div data={props}/>
        </>
        : "" }
      {gaucherCompletedSurveyQuestionCharts() ? 
        <>
        <GaucherQuestion4Div data={props}/>
        <GaucherQuestion5Div data={props}/>
        <GaucherQuestion6Div data={props}/>
        <GaucherQuestion7Div data={props}/>
        </>
        : "" }

      {haeCompletedSurveyQuestionCharts() ? 
        <>
        <HaeQuestion4Div data={props}/>
        <HaeQuestion5Div data={props}/>
        <HaeQuestion6Div data={props}/>
        <HaeQuestion8Div data={props}/>
        </>
        : "" }

      {((props.allTimeCount != null ) && (props.allTimeCount.length > 0)) ?
        <AllTimeUsersByMonthTable data={props.allTimeCount} /> : ""}
       
      {fabryOrHaeUncompletedSurveyUserByMonthTable() ?
        <QuarterByMonthDiv data={props}/> : "" }
      {fabryOrHaeUncompletedSurveyCharts() ?
        <div>
          <hr style={{paddingBottom: "20px"}}></hr>
          <UserEngagementDiv data={props} />
          <hr style={{paddingBottom: "20px"}}></hr>
          <FabreySurveyDiv data={props}/>
          <hr style={{paddingBottom: "20px"}}></hr>
        </div>  : "" }
      { gaucherUncompletedSurveyUserByMonthTable() ?
        <QuarterByMonthDiv data={props}/> : "" }
      { gaucherUncompletedSurveyCharts() ?
        <div>
          <hr style={{paddingBottom: "20px"}}></hr>
          <UserEngagementDiv data={props} />
          <hr style={{paddingBottom: "20px"}}></hr>
          <GaucherSurveyDiv data={props}/>
          <hr style={{paddingBottom: "20px"}}></hr>
        </div> : "" }
    </div>
    
  );
}
