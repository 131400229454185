import React from 'react';
import 'chart.js/auto';
import { Bar, Line, Pie } from 'react-chartjs-2';
import * as demographics from "../util/Demographics.js"

const gaucherSurveyLabels = ['Completed', 'Started', 'Initial', 'Followup_1', 'Followup_2', 'Followup_3' ];
const fabrySurveyLabels = ['Completed', 'Started', 'Initial', 'Followup'];
const userEngagementLabels = ['Completed', 'Exited Before Completion'];
const fabrySurveyLabels3 = ['Entered SM', 'Exited SM Before Completion', 'Completed SM', 'Downloaded TearSheet'];
const fabryQ5Labels = ["A positive test for Fabry as a newborn baby",
                      "Whorl pattern found in the cornea on eye exam (corneal whorl or verticillata)",
                      "A heart biopsy that found stored material in your heart muscle",
                      "Kidneys don't work as well as they should (renal failure) - when you were under 50 years old",
                      "A bottom left side of the heart that is too big (left ventricular hypertrophy)",
                      "Fabry disease",
                      "Heart rate is too slow (bradycardia)",
                      "Elevated protein in urine"];
const fabryQ6Labels = ["Severe whole body pain crises with fever or overexertion",
                      "Burning pain or tingling in hands or feet",
                      "Heat intolerance",
                      "Stroke (or mini-stroke) when you were under 50 years old",
                      "Decreased to no sweating"];
export const fabryQ8Labels = ["Feeling tired all the time (fatigue)",
                        "Chronic diarrhea",
                        "Ringing in the ears (tinnitus)",
                        "Red dots or non-itchy rash on lower belly (angiokeratoma)",
                        "Chest pain",
                        "Exercise causes burning pain or overheating (exercise intolerance)",
                        "Numbness or tingling in hands and feet (neuropathy)",
                        "Erection of the penis that lasts longer than 4 hours without medication (priapism)",  
                        "Swelling in the hands or feet from extra fluid (edema in extremities)",  
                        "Leaking heart valve (cardiac valve regurgitation)",
                        "Bloating",
                        "Elevated GFR blood test (a kidney function test)",
                        "Elevated serum creatinine blood test",
                        "Tests show the heart muscle is becoming too thick and stiff (hypertrophic cardiomyopathy)",
                        "Anxiety",
                        "Heart beats too fast or too slow (heart rhythm problems)",
                        "Dizziness (vertigo)",
                        "Constipation",
                        "Diarrhea with alternating constipation",
                        "Fatigue (feeling exhausted or tired)",
                        "Narrowed heart arteries (ischemic heart disease)",
                        "Depression",
                        "Fingers and toes turn white and blue when cold (Raynaud phenomenon)",
                        "Kidneys are not working properly (renal disease)",
                        "Decreased GFR blood test (type of kidney value)",
                        "Extremely sensitive to cold (cold intolerance)",
                        "Temporary partial vision loss, blind spots, zig-zag lines or seeing stars (visual migraines)",
                        "Hearing loss",
                        "Heartbeat doesn't feel right (fluttery, too fast, pounds or skips a beat) (Heart  conduction abnormalities)",
                        "Mini-stroke (TIA)",
                        "Fainting spells (syncope)",
                        "Abdominal pain",
                        "Panic Attacks"  ];
//abbreviated labels
const gaucherQ5Labels = ["Seizures or convulsions (any type)",
                        "ACE, TRAP, or chito",
                        "Supranuclear saccadic gaze palsy",
                        "Splenomegaly",
                        "Thrombocytopenia",
                        "Fetal hydrops"];
//abbreviated
const gaucherQ6Labels = ["Hepatosplenomegaly",
                        "Hepatomegaly",
                        "Easy bruising",
                        "Leg bone with an \"erlenmeyer flask deformity\" found on X-ray or MRI"];
export const gaucherQ7Labels = [
            "Joint pain (arthralgia)",
            "Severe bone pain caused by too little blood flow to the bones (bone crisis)",
						"Bone pain",
						"Death of bone due to decreased blood supply (avascular necrosis)",
						"Bones maturing at a slower rate than expected (delayed skeletal maturation)",
						"Bone infection (osteomyelitis)",
						"Weak or thinning bones (osteopenia or osteoporosis)",
						"Big or swollen belly",
						"Abdominal pain",
						"Part or all of the Spleen removed by surgery (partial or total splenectomy)",
						"Death of spleen tissue due to decreased blood supply (splenic infarction)",
						"Gallstones (cholelithiasis)",
						"Cardiac valve calcification",
						"Frequent nose bleeds",
						"Hard to stop bleeding after surgery or delivery of a baby (postpartum hemorrhage)",
						"Low number red blood, hemoglobin, or iron count (anemia)",
						"Vitamin B12 deficiency",
						"Low red blood cell count, low white blood cell count, and low platelets (pancytopenia)",
						"Abnormal M protein levels in the blood (Monoclonal gammopathy of unknown significance)",
						"Abnormal increase in production of antibodies (polyclonal gammopathy)",
						"Partial or complete loss of muscle movement (hypokinesia) or other Parkinsonian-like features",
						"An unsteady, staggering walk  (ataxia)",
						"Lung conditions caused by damaged/scarred tissue (pulmonary fibrosis, interstitial lung disease, alveolar/lobar consolidation)",
						"Increased blood pressure in blood vessels of lungs (pulmonary hypertension)",
						"Backward bending of the head (retroflexion of the neck)",
						"Thumbs often held inside fisted hands (cortical thumbs)",
						"Tired all the time (fatigue)",
						"Abnormally curved spine (Thoracic kyphosis)",
						"Delayed puberty (delayed pubertal development)"];
const haeQ5Labels = [
            "Hereditary angioedema",
            "Low serum C4 (also known as complement component 4 ) in your blood",
            "A low C1 inhibitor level in your blood",
            "Low C1 esterase inhibitor levels in your blood",
            [["Drug allergy to angiotensin-converting"], 
              ["enzyme [ACE] inhibitors that causes the throat or face to swell"]],
            "Drug allergy to estrogen, the hormone in many birth control pills the throat or face to swell",
            "A change/mutation in the SERPING1, F12, ANGPT1, PLG, or  KNG1 gene",
            "Allergic angioedema"
            ];
const haeQ6Labels = ["Repeated swelling in any part of the face, tongue, larynx, or throat without hives or an itching sensation",
                     "Edema in face, lips, tongue, larynx, or throat unrelated to confirmed food allergy or insect sting",
                     "Allergic reaction of face or throat swelling caused by specifically angiotensin-converting enzyme [ACE] inhibitors"
                    ];
export const haeQ8Labels = [
                    "Episode of shortness of breath caused by swelling tongue or throat",
                    "Almost suffocated/asphyxiated from swelling tongue or airway",
                    "Repeated acute abdominal  cramping episodes lasting more than 24 hours with no fever",
                    "Repeated sudden and severe abdominal swelling lasting more than 24 hours with no fever",
                    "Emergency room visit for appendix problems more than once",
                    "Face, tongue, or throat swelling that doesn't respond to antihistamines, corticosteroids, or epinephrine",
                    "Swelling of the genitals without an injury (genital edema)",
                    "Severe swelling after a dental procedure",
                    "Severe sudden swelling of face, tongue, or throat during pregnancy",
                    "Gastric mucosa inverting into esophagus",
                    "Severe swelling that did respond to a medication called a bradykinin B2 receptor inhibitor or agonist",
                    "Allergic reaction of face or throat swelling after starting birth control pills"
                    ];
const ageChartLabels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
                       '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
                       '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
                       '31', '32', '33', '34', '35', '36', '37', '38', '39', '40',
                       '41', '42', '43', '44', '45', '46', '47', '48', '49', '50',
                       '51', '52', '53', '54', '55', '56', '57', '58', '59', '60',
                       '61', '62', '63', '64', '65', '66', '67', '68', '69', '70',
                       '71', '72', '73', '74', '75', '76', '77', '78', '79', '80',
                       '81', '82', '83', '84', '85', '86', '87', '88', '89', '90',
                       '91', '92', '93', '94', '95', '96', '97', '98', '99', '100'];

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
     
  };
  
  const horizontalBarChartOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      labels: {
        color: 'blue'
      },
      title: {
        display: true,
        /*text: 'Horizontal Bar Chart',*/
      },
    },
  };

function getRandomColor() {
    var letters = '0123456789ABCDEF'.split('');
    var color = '#';
    for (var i = 0; i < 6; i++ ) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
function backgroundColors() {
  let colors = [];
  for (let i = 0; i < 34; i++ ) {
      colors.push( getRandomColor() );
  }
  return colors;
}

const getCustomPieLabels = ( labels, data ) => {
    let sum = data.reduce( (a, b) => { return a + b; }, 0);
    //return labels.map((label,index) =>`${label}: ${(data[index]*100/sum).toFixed(0)}%`);
    return labels.map((label,index) =>`${label}: ${(data[index]).toFixed(0)} / ${(data[index]*100/sum).toFixed(0)}%`);
}

  const RiskLevelChart = (props) => (
    <>
      <h3>Completed SM User: Risk Level</h3>
      <Bar data={{
                  labels: [demographics.HIGH_RISK, demographics.INCREASED_RISK, demographics.NO_RISK],
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                      ],
                      borderWidth: 1,
                    }],
                }} options={options}/>
    </>
  );

  const GenderChart = (props) => (
    <>
      <h3>Completed SM User: Gender</h3>
      <Bar data= {{ 
                  labels: ['Female', 'Male'],
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                      ],
                      borderColor: [
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                      ],
                      borderWidth: 1,
                    }],
                }} options={options}/>
    </>
  );
    
  const AgeChart = (props) => (
    <>
      <h3>Completed SM User: Age</h3>
      <Line data= {{labels: ageChartLabels,
              datasets: [{
                  label: 'count',
                  data: props.data,
                  fill: false,
                  backgroundColor: 'rgba(255, 159, 64, 0.2)',
                  borderColor: 'rgba(255, 159, 64, 1)',
                }]
              }}
            options={options} /> 
    </>
  );
 
  const FabryQ4Chart = (props) => (
    <>
      <Bar data= {{ 
                  labels: ["Yes", "No", "Don't know"],
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                      ],
                      borderWidth: 1,
                    }],
                }} options={horizontalBarChartOptions}/>
    </>
  );

  const HaeQ4Chart = (props) => (
    <>
      <Bar data= {{ 
                  labels: ["Yes", "No", "Don't know"],
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                      ],
                      borderWidth: 1,
                    }],
                }} options={horizontalBarChartOptions}/>
    </>
  );

  const HaeQ5PieChart = (props) => (
    <>
      <h3>Has your doctor told you that you have:</h3>
      <Pie data= {{ 
                  labels: getCustomPieLabels(haeQ5Labels, props.data),
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(122, 205, 86, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(201, 203, 207, 0.2)',
                      ],
                      borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255, 205, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(122, 205, 86, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(201, 203, 207, 1)',
                      ],
                      borderWidth: 1,
                    }],
                    
                }}/>
    </>
  );

  const HaeQ6PieChart = (props) => (
    <>
      <h3>Have you experienced any of the following:</h3>
      <Pie data= {{ 
                  labels: getCustomPieLabels(haeQ6Labels, props.data),
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)'
                      ],
                      borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(255, 205, 86, 1)'
                      ],
                      borderWidth: 1,
                    }],
                }}/>
    </>
  );

  const HaeQ8PieChart = (props) => (
    <>
      <h3>Have you had any of the following:</h3>
      <Pie data={{
                labels: getCustomPieLabels(haeQ8Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: backgroundColors(),
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)',
                      'rgb(153, 102, 255)',
                      'rgb(201, 203, 207)',
                    ],
                    borderWidth: 1,
                  }],
            }}/>
      
    </>
  );

  const GaucherQ4Chart = (props) => (
    <>
      <Bar data= {{ 
                  labels: ["Yes", "No", "Don't know"],
                  datasets: [{
                      label: 'count',
                      data: props.data,
                      backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                      ],
                      borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                      ],
                      borderWidth: 1,
                    }],
                }} options={horizontalBarChartOptions}/>
    </>
  );


  const FabrySurveyBarChart = (props) => (
    <>
      User count by survey stage
      <Bar data={{
                labels: fabrySurveyLabels,
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                     
                    ],
                    borderColor: [
                      'rgba(75, 192, 192, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                     
                    ],
                    borderWidth: 1,
                  }],
            }}
          options={horizontalBarChartOptions}/>

      <p style={{paddingTop: "30px"}}>Uncompleted survey stages:</p>
      <ul>
        <li>Started - users who quit SM before or after submitting the demographics questions</li>
        <li>Initial - users who quit SM after submitting the first group of medical questions</li>
        <li>Followup - users who quit SM after submitting the second group of medical questions</li>
      </ul>
      <p style={{paddingTop: "30px"}}>Note: Users who submitted user stories have completed the survey</p>
    </>
  );
   
  const FabrySurveyBarChart3 = (props) => (
    <>
      User count by survey stage
      <Bar data={{
                labels: fabrySurveyLabels3,
                datasets: [{
                    barPercentage: 0.5,
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                     
                    ],
                    borderColor: [
                      'rgba(75, 192, 192, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                     
                    ],
                    borderWidth: 1,
                  }],
            }}
          options={horizontalBarChartOptions}/>
     
    </>
  );

  const FabrySurveyPieChart = (props) => (
    <>
      User count by survey stage
      <Pie data={{
                labels: getCustomPieLabels(fabrySurveyLabels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                     
                    ],
                    borderColor: [
                      'rgba(75, 192, 192, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
    
                    ],
                    borderWidth: 1,
                  }],
            }}/>
      
    </>
  );

const FabryQ5PieChart = (props) => (
    <>
      Has your doctor told you that you have:
      <Pie data={{
                labels: getCustomPieLabels(fabryQ5Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgb(122, 205, 86, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(201, 203, 207, 0.2)',
                    ],
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)',
                      'rgb(153, 102, 255)',
                      'rgb(201, 203, 207)',
                    ],
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const FabryQ6PieChart = (props) => (
    <>
      Have you experienced any of the following:
      <Pie data={{
                labels: getCustomPieLabels(fabryQ6Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgb(122, 205, 86, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(201, 203, 207, 0.2)',
                    ],
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)',
                      'rgb(153, 102, 255)',
                      'rgb(201, 203, 207)',
                    ],
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const FabryQ8PieChart = (props) => (
    <>
      Has your doctor told you of any of the following:
      <Pie data={{
                labels: getCustomPieLabels(fabryQ8Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: backgroundColors(),
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)',
                      'rgb(153, 102, 255)',
                      'rgb(201, 203, 207)',
                    ],
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const GaucherQ5PieChart = (props) => (
    <>
      Has your doctor told you you have any of the following:
      <Pie data={{
                labels: getCustomPieLabels(gaucherQ5Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgb(122, 205, 86, 0.2)'
                      ],
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)'
                      ],
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const GaucherQ6PieChart = (props) => (
    <>
      Has your doctor told you of any of the following:
      <Pie data={{
                labels: getCustomPieLabels(gaucherQ6Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(255, 205, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgb(122, 205, 86, 0.2)'
                      ],
                    borderColor: [
                      'rgb(255, 99, 132)',
                      'rgb(255, 159, 64)',
                      'rgb(255, 205, 86)',
                      'rgb(75, 192, 192)',
                      'rgb(54, 162, 235)',
                      'rgb(122, 205, 86)'
                      ],
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const GaucherQ7PieChart = (props) => (
    <>
      Has your doctor told you of or do you have any of the following:
      <Pie data={{
                labels: getCustomPieLabels(gaucherQ7Labels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: backgroundColors(),
                    borderWidth: 1,
                  }],
            }}/>
    </>
  );

  const UserEngagementPieChart = (props) => (
    <>
      User Engagement
      <Pie data={{
                labels: getCustomPieLabels(userEngagementLabels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                     
                    ],
                    borderColor: [
                      'rgba(75, 192, 192, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
    
                    ],
                    borderWidth: 1,
                  }],
            }}/>
      
    </>
  );

  const GaucherSurveyBarChart = (props) => (
    <>
      User count by survey stage
      <Bar data={{
                labels: gaucherSurveyLabels,
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  }],
              }}
          options={options} />
      <p style={{paddingTop: "30px"}}>Gaucher Uncompleted Survey Stages:</p>
      <ul>
        <li>Started - users who quit SM before or after submitting the demographics questions (q1,q2,q3)</li>
        <li>Initial - users who quit SM after submitting the first group of medical questions (q4,q5)</li>
        <li>Followup_1 - users who quit SM after submitting the second group of medical questions (q6)</li>
        <li>Followup_2 - users who quit SM after submitting the third group of medical questions (q7)</li>
        <li>Followup_3 - users who quit SM after submitting the fourth group of medical questions (q10)</li>
      </ul>
      <p style={{paddingTop: "30px"}}>Note: Users who submitted user stories have completed the survey</p>
    </>
  );

  const GaucherSurveyPieChart = (props) => (
    <>
      User count by survey stage
      <Pie data={{
                labels: getCustomPieLabels(gaucherSurveyLabels, props.data),
                datasets: [{
                    label: 'count',
                    data: props.data,
                    backgroundColor: [
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 99, 132, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                  }],
                  pieceLabel: {
                    render: 'value'
                 }
            }}/>
    </>
  );
  
  
export {RiskLevelChart,
        GenderChart,
        AgeChart,
        FabryQ4Chart,
        FabryQ5PieChart,
        FabryQ6PieChart,
        FabryQ8PieChart,
        FabrySurveyBarChart,
        FabrySurveyBarChart3,
        FabrySurveyPieChart,
        GaucherSurveyBarChart,
        GaucherSurveyPieChart,
        UserEngagementPieChart,
        GaucherQ4Chart,
        GaucherQ5PieChart,
        GaucherQ6PieChart,
        GaucherQ7PieChart,
        HaeQ4Chart,
        HaeQ5PieChart,
        HaeQ6PieChart,
        HaeQ8PieChart
        };
  


    
