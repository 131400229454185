import React, {useRef} from "react";
import { useReactToPrint } from "react-to-print";
import "bootstrap/dist/css/bootstrap.min.css";
import "../symptom-screener.css";

const getEvaluationHtml = ( value ) => {
  let html = "invalid assessment case";
  if (value && (typeof value === 'number' || parseInt(value))) {
    switch (parseInt(value)) {
      case 1:
        html = 'it is less likely that you have Gaucher disease.';
        break;
      case 2:
        html = 'it is possible that you have Gaucher disease.';
        break;
      case 3:
        html = 'you are at risk to have Gaucher disease.';
        break;
      default:
        alert('invalid assessment case');
      }
  }else {
    alert('invalid assessment case');
  }
  return html;
}


function getQ1PromptText(){
  return "Are you learning about your disease risk for:";
}

function getQ1ResponseText( value ){
  if( value === "Self"){
     return "Self";
  }else {
    return "Other";
  }
}

function getQ2PromptText(){
  return "What is your gender?"
}

function getQ2ResponseText( value ){
  if( value === "Male"){
    return "Male";
  }else {
    return "Female";
  }
}

function getQ3PromptText(){
  return "What is your age?";
}

function getQ4PromptText(){
  return "Has anyone in your family had:";
}

function getQ4PartPromptText( value ){
  
  if( value === "Gaucher disease" ){
    return "Gaucher disease";
  
  }else if ( value === "A baby who died before 2 years of age"){
    return "A baby who died before 2 years of age";
  
  }else if ( value === "A baby who died before birth (Still birth of fetal demise)" ){
    return "A baby who died before birth (Still birth of fetal demise)";
  
  }else if( value === "A baby who was born encased in a tight shiny film that looks a little like plastic wrap (Collodion baby)"){
    return "A baby who was born encased in a tight shiny film that looks a little like plastic wrap (Collodion baby)";
  
  }else{
    return "invalid prompt";
  
  }
}

function getQ4ResponseText( value ){
  
  if( value === "Yes"){
    return "Yes";
  }else if ( value === "No" ){
    return "No";
  }else if ( value === "Don't Know" ){
    return "Don't Know";
  }else {
    return "invalid response";
  }
}

function getQ5PromptText(){
  return "Has your doctor told you you have any of the following:";

}
  
function getQ5ResponseText( value ){
  
  if( value === "Seizures or convulsions (any type)"){
    return "Seizures or convulsions (any type)";
  }else if ( value === "Increased blood levels of angiotensin converting enzyme (ACE),  tartrate-resistant acid phosphatase (TRAP), or chitotriosidase (chito)" ){
    return "Increased blood levels of angiotensin converting enzyme (ACE),  tartrate-resistant acid phosphatase (TRAP), or chitotriosidase (chito)";
  
  }else if ( value === "Problems moving eyes side-to-side to watch a moving object or person (Supranuclear saccadic gaze palsy)" ){
    return "Problems moving eyes side-to-side to watch a moving object or person (Supranuclear saccadic gaze palsy)";
  
  }else if ( value === "Big spleen (splenomegaly)" ){
    return "Big spleen (splenomegaly)";
  
  }else if ( value === "Low platelets levels in the blood  (thrombocytopenia)" ){
    return "Low platelets levels in the blood  (thrombocytopenia)";
  
  }else if ( value === "Swelling of a baby's body before birth (fetal hydrops)" ){
    return "Swelling of a baby's body before birth (fetal hydrops)";
  
  }else {
    return "invalid response";
  
  }
}

function getQ6PromptText(){
  
  return "Has your doctor told you of any of the following:";

}
  
function getQ6ResponseText( value ){
  
  if( value === "Enlarged spleen AND liver (hepatosplenomegaly)"){
    return "Enlarged spleen AND liver (hepatosplenomegaly)";
  
  }else if ( value === "Enlarged liver (hepatomegaly)" ){
    return "Enlarged liver (hepatomegaly)";
  
  }else if ( value === "Easy bruising" ){
    return "Easy bruising";
  
  }else if ( value === "Leg bone with an \"erlenmeyer flask deformity\" found on X-ray or MRI" ){
    return "Leg bone with an \"erlenmeyer flask deformity\" found on X-ray or MRI";
  
  }else {
    return "invalid response";
  }
}

function getQ7PromptText(){
  return "Has your doctor told you of or do you have any of the following:";

}
  
function getQ7ResponseText( value ){
  
  if( value === "Joint pain (arthralgia)"){
    return "Joint pain (arthralgia)";
  }else if ( value === "Severe bone pain caused by too little blood flow to the bones (bone crisis)" ){
    return "Severe bone pain caused by too little blood flow to the bones (bone crisis)";
  }else if ( value === "Bone pain" ){
    return "Bone pain";
  }else if ( value === "Death of bone due to decreased blood supply (avascular necrosis)" ){
    return "Death of bone due to decreased blood supply (avascular necrosis)";
  }else if ( value === "Bones maturing at a slower rate than expected (delayed skeletal maturation)" ){
    return "Bones maturing at a slower rate than expected (delayed skeletal maturation)";
  }else if ( value === "Bone infection (osteomyelitis)" ){
    return "Bone infection (osteomyelitis)";
  }else if ( value === "Weak or thinning bones (osteopenia or osteoporosis)" ){
    return "Weak or thinning bones (osteopenia or osteoporosis)";
  }else if ( value === "Big or swollen belly" ){
    return "Big or swollen belly";
  }else if ( value === "Abdominal pain" ){
    return "Abdominal pain";
  }else if ( value === "Part or all of the Spleen removed by surgery (partial or total splenectomy)" ){
    return "Part or all of the Spleen removed by surgery (partial or total splenectomy)";
  
  }else if ( value === "Death of spleen tissue due to decreased blood supply (splenic infarction)" ){
    return "Death of spleen tissue due to decreased blood supply (splenic infarction)";
  
  }else if ( value === "Gallstones (cholelithiasis)" ){
    return "Gallstones (cholelithiasis)";
  
  }else if ( value === "Cardiac valve calcification" ){
    return "Cardiac valve calcification";
  }else if ( value === "Frequent nose bleeds" ){
    return "Frequent nose bleeds";
  
  }else if ( value === "Hard to stop bleeding after surgery or delivery of a baby (postpartum hemorrhage)" ){
    
    return "Hard to stop bleeding after surgery or delivery of a baby (postpartum hemorrhage)";
  
  }else if ( value === "Low number red blood, hemoglobin, or iron count (anemia)" ){
    
    return "Low number red blood, hemoglobin, or iron count (anemia)";
  
  }else if ( value === "Vitamin B12 deficiency" ){
    
    return "Vitamin B12 deficiency";
  
  }else if ( value === "Low red blood cell count, low white blood cell count, and low platelets (pancytopenia)" ){
    
    return "Low red blood cell count, low white blood cell count, and low platelets (pancytopenia)";
  
  }else if ( value === "Abnormal M protein levels in the blood (Monoclonal gammopathy of unknown significance)" ){
    
    return "Abnormal M protein levels in the blood (Monoclonal gammopathy of unknown significance)";
  
  }else if ( value === "Abnormal increase in production of antibodies (polyclonal gammopathy)" ){
    
    return "Abnormal increase in production of antibodies (polyclonal gammopathy)";
  
  }else if ( value === "Partial or complete loss of muscle movement (hypokinesia) or other Parkinsonian-like features" ){
    
    return "Partial or complete loss of muscle movement (hypokinesia) or other Parkinsonian-like features";
  
  }else if ( value === "An unsteady, staggering walk  (ataxia)" ){
    
    return "An unsteady, staggering walk  (ataxia)";
  
  }else if ( value === "Lung conditions caused by damaged/scarred tissue (pulmonary fibrosis, interstitial lung disease, alveolar/lobar consolidation)" ){
    
    return "Lung conditions caused by damaged/scarred tissue (pulmonary fibrosis, interstitial lung disease, alveolar/lobar consolidation)";
  
  }else if ( value === "Increased blood pressure in blood vessels of lungs (pulmonary hypertension)" ){
    
    return "Increased blood pressure in blood vessels of lungs (pulmonary hypertension)";
  
  }else if ( value === "Backward bending of the head (retroflexion of the neck)" ){
    
    return "Backward bending of the head (retroflexion of the neck)";
  
  }else if ( value === "Thumbs often held inside fisted hands (cortical thumbs)" ){
    
    return "Thumbs often held inside fisted hands (cortical thumbs)";
  
  }else if ( value === "Tired all the time (fatigue)" ){
    
    return "Tired all the time (fatigue)";
  
  }else if ( value === "Abnormally curved spine (Thoracic kyphosis)" ){
    
    return "Abnormally curved spine (Thoracic kyphosis)";
  
  }else if ( value === "Delayed puberty (delayed pubertal development)" ){
    
    return "Delayed puberty (delayed pubertal development)";
  
  }else {
    
    return "invalid response";
  
  }
}

function getQ8PromptText(){
  return "Rate how much each of the following stories sounds like you:";
}

function getQ8KeyText( value ){

  if( value === "Gaucher Type 1"){
    return "Gaucher Type 1";
  
  }else if( value === "Gaucher Type 2"){
    return "Gaucher Type 2";
  
  }else if ( value === "Gaucher Type 3" ){
    return "Gaucher Type 3";
    
  }else {
    return "invalid response";
    
  }
}

function getQ8ResponseText( value ){
     
  if( value === "Not at all"){
    return "Not at all";
  
  }else if ( value === "Not much" ){
    return "Not much";

  }else if ( value === "Somewhat" ){
    return "Somewhat";
  
  }else if ( value === "A lot" ){
    return "A lot";
    
  }else {
    return "invalid response";
    
  }
}

function getQ10ResponseText( value ){
     
  if( value === "Low platelets (thrombocytopenia)"){
  
    return "Low platelets (thrombocytopenia)";
  
  }else if ( value === "Enlarged liver (hepatomegaly)" ){

    return "Enlarged liver (hepatomegaly)";

  }else if ( value === "Sharply turning the head in a jerky, thrusting fashion (head-thrusting)" ){
  
    return "Sharply turning the head in a jerky, thrusting fashion (head-thrusting)";
  
  }else if ( value === "Excessive blinking" ){
  
    return "Excessive blinking";
    
  }else if ( value === "Inability to gain weight and grow at the expected rate (failure to thrive)" ){

    return "Inability to gain weight and grow at the expected rate (failure to thrive)";

  }else if ( value === "A harsh vibrating noise when breath holding (stridor with breath holding)" ){
  
    return "A harsh vibrating noise when breath holding (stridor with breath holding)";
  
  }else if ( value === "Difficulty swallowing (dysphagia)" ){
  
    return "Difficulty swallowing (dysphagia)";
    
  }else if ( value === "Baby losing previously acquired motor skills" ){

    return "Baby losing previously acquired motor skills";

  }else if ( value === "Exhibit low muscle tone (hypotonia)" ){
  
    return "Exhibit low muscle tone (hypotonia)";
  
  }else if ( value === "Shows signs of involuntary muscle spasms (spasticity) that result in slow, stiff movements of the arms and legs" ){
  
    return "Shows signs of involuntary muscle spasms (spasticity) that result in slow, stiff movements of the arms and legs";
    
  }else if ( value === "Crossed eyes (strabismus)" ){

    return "Crossed eyes (strabismus)";

  }else if ( value === "Abnormal positioning or bending of the neck (retroflexion)" ){
  
    return "Abnormal positioning or bending of the neck (retroflexion)";
  
  }else if ( value === "Have a high-pitched breathing (stridor) due to contraction of the muscles of the voice box" ){
  
    return "Have a high-pitched breathing (stridor) due to contraction of the muscles of the voice box";
  
  }else if ( value === "Weakness and wasting of the body due to severe chronic illness (cachexia)" ){
  
    return "Weakness and wasting of the body due to severe chronic illness (cachexia)";
    
  }else if ( value === "Backward bending of the head (retroflexion of the neck, thumbs often held inside fisted hands (cortical thumbs),  or other pyramidal signs" ){

    return "Backward bending of the head (retroflexion of the neck, thumbs often held inside fisted hands (cortical thumbs),  or other pyramidal signs";

  }else if ( value === "Eyes turn slightly in (convergent strabismus)" ){
  
    return "Eyes turn slightly in (convergent strabismus)";
  
  }else if ( value === "Decreased bodily movement (hypokinesia)" ){
  
    return "Decreased bodily movement (hypokinesia)";
    
  }else if ( value === "Very small head (microcephaly)" ){

    return "Very small head (microcephaly)";

  }else if ( value === "Development delays" ){
  
    return "Development delays";
         
  }else if ( value === "Joint contractures (arthrogryposis)" ){

    return "Joint contractures (arthrogryposis)";

  }else if ( value === "Feeding difficulties" ){
  
    return "Feeding difficulties";
  
  }else if ( value === "Stopping breathing for no specific reason (spontaneous apnea)" ){
  
    return "Stopping breathing for no specific reason (spontaneous apnea)";
    
  }else if ( value === "Spasm of the muscles causing backward arching of the head, neck, and spine (opisthotonos)" ){

    return "Spasm of the muscles causing backward arching of the head, neck, and spine (opisthotonos)";

  }else if ( value === "Acid reflux (gastrointestinal reflux)" ){
  
    return "Acid reflux (gastrointestinal reflux)";
  
  }else if ( value === "Frequent Choking" ){
  
    return "Frequent Choking";
    
  }else {
  
    return "invalid response";
    
  }
}


export default function GaucherTearsheet( props ){

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //onClick={() => window.print()}
  const imageStyle = {
    width: "75px",
    height: "100px",
    display: "inline-block",
    position: "absolute"
  };

  const divStyle = {
    border: "1px solid #ccc",
    paddingLeft: "20px",
    backgroundColor: "#f3f4f4",
    width: "100%"
    
  };
  const h3Style ={
    color: "rgb(158,78,135)",
    fontWeight: "600",
    fontFamily: "Roboto",
    fontSize: "18px",
    margin: "20px 0px 18px"
  }
  const h4Style ={
    fontFamily: "Robotto",
    fontSize: "14px",
    fontWeight: "bold"
  }

  const tableStyle = {
    width: "100%",
    border: "1px solid #efefef"
  };

  const thQuestionStyle= {
    width: "40%"
  };

  const thResponseStyle= {
    width: "60%"
  }

  const tearsheetDivStyle = {
    border: "1px solid #ccc",
    padding: "20px"
  }

  const h1Style = {
    fontSize: "28px",
    fontWeight: "700",
    color: "#9e4e87"
  }
  
 
const question1Response = () => {
  if (props.data.userResponses && props.data.userResponses.question1Response) {
      return <tr><td><b>{getQ1PromptText()}</b></td><td><p>{getQ1ResponseText(props.data.userResponses.question1Response.value)}</p></td></tr>;
  }
}
const question2Response = () => {
  if (props.data.userResponses && props.data.userResponses.question2Response) {
    return <tr><td><b>{ getQ2PromptText()} </b></td><td><p>{ getQ2ResponseText( props.data.userResponses.question2Response.value) }</p></td></tr>;
  }
}
const question3Response = () => {
  if (props.data.userResponses && props.data.userResponses.question3Response) {
    return <tr><td><b>{ getQ3PromptText()}</b></td><td><p>{ props.data.userResponses.question3Response.value }</p></td></tr>;
  }
}

const question4Prompt = () => {
  if (props.data.userResponses && props.data.userResponses.question4Response) {
    return <tr><td colSpan="2"><b>{getQ4PromptText()}</b></td></tr>;
  }
}

  return (
      
  <div className="container">
    <div className="row">
      <div className="symptom-screener-dialogue-item" >
        <div style={imageStyle}>
          <img src={process.env.PUBLIC_URL + '/gene.png'}></img>
        </div>
        <div className="symptom-screener-dialogue-bubble">
          <div className="symptom-screener-dialogue-prompt">
            <p>The report below contains the results of your Fabry risk assessment. You can print this for your records, as well as share it electronically with your doctor and other medical professionals.</p>
            <p><b><i>Based on the symptoms you shared on SymptomMatcher,&nbsp;</i><i id="symptom-screener-assessment-evaluation">{getEvaluationHtml(props.data.evaluation)}</i></b></p>
            <p>Please review the information below with your doctor and discuss next steps such as testing for Fabry disease or obtaining a referral to a Fabry expert.</p>
          </div>
        </div>
      </div>
      <div className="row symptom-screener-tearsheet">
        {/*<div className="symptom-screener-button-container">
          <button className="symptom-screener-print-button" type="button" onClick={handlePrint}>
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Print results for your doctor</div>
          </button>
          <button className="symptom-screener-result-button"  type="button">
            <div className="symptom-screener-button-div-1"></div>
            <div className="symptom-screener-button-div-2">Read more about Fabry disease</div>
          </button>
        </div>
        <br />
        <br />
        */}
           
        <div style={divStyle}>
          <h3 style={h3Style}>Your Personalized SymptomMatcher Results</h3>
        </div>
      
        <div style={tearsheetDivStyle}>
          {/*
          <h4 style = {h4Style}><b>Patient Information and Instructions:</b></h4>
          <hr />
          <h1 style={h1Style}>Physician Tear Sheet</h1>
          <h1 style={h1Style}>Fabry disease</h1>*/}
          <h1 style={h1Style}>Indicators/Symptoms</h1>
          <p><i>These are symptoms your patient has said they have, they don't have, or they are not sure if they have. The symptoms are grouped according to likelihood of frequency in a person who has this presentation of Fabry disease.</i></p>
          <table style={tableStyle}>
            <thead><tr>
              <th style={thQuestionStyle}>Question</th>
              <th style={thResponseStyle}>Response</th>
            </tr></thead>
            <tbody id="symptom-screener-assessment-user-responses">
              {question1Response()}
              {question2Response()}
              {question3Response()}
              {question4Prompt()}
              {
                Object.keys(props.data.userResponses.question4Response.value).map((part)=>{
                  return <tr><td><p>{props.data.userResponses.question4Response.value[part].prompt}</p></td>
                             <td><p>{props.data.userResponses.question4Response.value[part].value}</p></td></tr>
              })}
              { props.data.userResponses.question6Response != null ? 
                <tr><td><b>{getQ5PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question5Response.value.map( (value) => {
                          return <li> {getQ5ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
                </tr>: ""
              }
              { props.data.userResponses.question6Response != null ? 
                <tr><td><b>{getQ6PromptText()}</b></td>
                  <td>
                    <ul>
                      {props.data.userResponses.question6Response.value.map( (value) => {
                          return <li> {getQ6ResponseText( value )}</li>
                        })
                      }
                    </ul>
                  </td>
                </tr>: ""
              }
              { props.data.userResponses.question7Response != null ? 
                <tr><td><b>{getQ7PromptText()}</b></td>
                    <td>
                      <ul>
                        {props.data.userResponses.question7Response.value.map( (value) => {
                            return <li> {getQ7ResponseText( value )}</li>
                          })
                        }
                      </ul>
                    </td>
                </tr>: ""
              }
              { props.data.userResponses.question8Response != null ? 
                <tr>
                  <td colSpan="2"><b> {getQ8PromptText()}</b></td>
                </tr> : ""
              }
              { props.data.userResponses.question8Response != null ? 
                Object.keys(props.data.userResponses.question8Response.value).map((key) => {
                  return <tr>
                          <td><p>{getQ8KeyText( key )}</p></td>
                          <td><p>{getQ8ResponseText( props.data.userResponses.question8Response.value[key].value )}</p></td>
                          </tr>
                }): ""
              }
              { props.data.userResponses.question10Response != null ? 
                  <tr><td><b>{getQ6PromptText()}</b></td>
                      <td>
                        <ul>{ props.data.userResponses.question10Response.value.map((value) => {
                            <li>{getQ10ResponseText( value )}</li>
                            })}
                        </ul>
                      </td>
                    </tr>: ""
              }
              { props.data.userResponses.question11Response != null ?
                <tr>
                  <td colSpan="2"><b>{getQ8PromptText()}</b></td>
                </tr>: ""
              }
              { props.data.userResponses.question11Response != null ?
                Object.keys(props.data.userResponses.question11Response.value).map((key)=> {
                  return <tr><td><p>{getQ8KeyText( key )}</p></td>
                           <td><p>{getQ8ResponseText( props.data.userResponses.question11Response.value[key].value )}</p></td>
                        </tr>
                        }): ""
              }
                              
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
        
  );
};

